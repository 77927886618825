import React from 'react';
import { Button, Card, CardBody, CardDeck } from 'reactstrap';
import { strings } from '../../../constants/localization';

import {
  currencyFormatDA,
  formatAmount,
  formatInterestRate,
} from '../../../constants/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../../constants';
import { ReactComponent as InformationIcon } from '../../../resources/icons/info-circle.svg';

interface SundhedCreditProps {
  creditFacilityData: {
    status: string;
    amountAvailable: number;
    expirationDate: string;
    interestRate: number;
    amount: number;
    dunning: boolean;
    creditCardData: {
      status: string;
    };
    installmentLoanDataList: [
      {
        number: string;
        status: string;
      }
    ];
  };
  clientData: {
    uuid: string;
  };
}

const SundhedCreditInfo: React.FC = () => {
  const creditInformation = useSelector(
    (state: { memberPortal: { clientDataInformation: SundhedCreditProps } }) =>
      state.memberPortal?.clientDataInformation
  );

  const clientCreditInformation = creditInformation.creditFacilityData;

  const { uuid } = creditInformation.clientData;

  const creditCardDataStatus =
    clientCreditInformation?.creditCardData.status || '';

  const latestInstallmentLoanData =
    clientCreditInformation?.installmentLoanDataList;

  const loanInvoiceNumber = latestInstallmentLoanData.length
    ? latestInstallmentLoanData[latestInstallmentLoanData.length - 1].number
    : undefined;

  const filteredOngoingPaymentList =
    clientCreditInformation.installmentLoanDataList.filter(
      (invoice) => invoice.status === 'ACCEPTED'
    );

  const timeDiference = moment().diff(
    moment(clientCreditInformation.expirationDate).add(1, 'days')
  );

  const isExpired = timeDiference >= 0 ? true : false;
  const isActive = clientCreditInformation.status === 'ACTIVE';
  const isDisabled = clientCreditInformation.status === 'DISABLED';
  const isRejected = clientCreditInformation.status === 'REJECTED';
  const latePayment = clientCreditInformation.status === 'LATE_PAYMENT';
  const isAmountBelowZero = clientCreditInformation.amountAvailable <= 0;
  const { dunning } = clientCreditInformation;

  const isSuspended = clientCreditInformation.status === 'SUSPENDED';

  const creditStatusColour = (function () {
    const colours = {
      activeAndNotExpired: 'green',
      latePayment: 'red',
      rejected: 'red',
      suspended: 'yellow',
      disabled: 'grey',
      fullyUtilized: 'grey',
    };

    return function getCreditStatusColour() {
      if (latePayment) return colours.latePayment;
      if (isSuspended) return colours.latePayment;

      if (isActive && !isExpired && !dunning && !isAmountBelowZero)
        return colours.activeAndNotExpired;
      if (isActive && isExpired && !dunning) return colours.latePayment;
      if (isActive && dunning) return colours.suspended;
      if (isDisabled && !dunning) return colours.disabled;
      if (isActive && !isExpired && !dunning && isAmountBelowZero)
        return colours.fullyUtilized;

      return colours.latePayment;
    };
  })();

  const amountWithLineThrough =
    (isActive && isExpired) || (isActive && dunning);

  const renderWarningCard = () => {
    const hasActiveAgreement = filteredOngoingPaymentList.length > 0;
    const hasActiveCreditCard = creditCardDataStatus === 'ACTIVE';
    const missingCreditCard = creditCardDataStatus === null;

    const failedCreditCard = creditCardDataStatus === 'FAILED';
    const inactivatedCreditCard = creditCardDataStatus === 'INACTIVATED';

    if (!latePayment && hasActiveAgreement) {
      // no card is associated with the credit facility
      if (missingCreditCard || inactivatedCreditCard) {
        return renderCard(
          false,
          null,
          strings.mitWarningCardForMissingCreditCard,
          strings.mitRenewCreditCard,
          `${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${loanInvoiceNumber}`,
          'yellow'
        );
      }
      // The card has been hard declined
      if (failedCreditCard) {
        return renderCard(
          false,
          null,
          strings.mitCreditCardNotActive,
          strings.mitRenewCreditCard,
          `${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${loanInvoiceNumber}`,
          'yellow'
        );
      }

      // Is active && duning true === insufficient funds
      if (hasActiveCreditCard && dunning) {
        return renderCard(
          false,
          null,
          strings.mitCreditCardInsufficientFunds,
          strings.mitRenewCreditCard,
          `${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${loanInvoiceNumber}`,
          'yellow'
        );
      }
    }

    switch (clientCreditInformation.status) {
      case 'LATE_PAYMENT':
        return renderCard(
          false,
          null,
          strings.mitNoteForLatePayment,
          null,
          null,
          'yellow'
        );

      case 'REJECTED':
        return null;
      case 'DISABLED':
        if (!hasActiveAgreement) {
          return renderCard(
            false,
            null,
            strings.mitWarningCardForDisabledAndRejectedWithNoActiveAgreement,
            strings.mitButtonForDisabledNote,
            `https://${process.env.REACT_APP_ONBOARDING_DOMAIN}/?tag=forny&companyId=X001&leadUuid=${uuid}&creditExpirationDate=${clientCreditInformation.expirationDate}`,
            'green'
          );
        }
        break;

      case 'ACTIVE':
        if (isExpired && !dunning && !isAmountBelowZero) {
          return renderCard(
            false,
            null,
            strings.mitNoteForExpiredCredit,
            strings.mitStartCheckCreditButton,
            `https://${process.env.REACT_APP_ONBOARDING_DOMAIN}/?tag=forny&companyId=X001&leadUuid=${uuid}&creditExpirationDate=${clientCreditInformation.expirationDate}`,
            'yellow'
          );
        }

        if (!isExpired && !dunning && isAmountBelowZero) {
          return renderCard(
            true,
            <InformationIcon className='infoIcon' />,
            strings.mitCreditIsFullyUtilized,
            null,
            null,
            'yellow'
          );
        }
        break;

      default:
        return null;
    }
  };

  const renderCard = (
    hasIcon: boolean,
    // eslint-disable-next-line no-undef
    Icon: JSX.Element | string,
    text: string,
    buttonText: string,
    link: string,
    color: string
  ) => (
    <div className={`warning-card ${color}`}>
      {hasIcon && Icon && (
        <div className='warning-icon'>
          {typeof Icon === 'string' ? <span>{Icon}</span> : Icon}
        </div>
      )}
      <div className='warning-text'>
        <p>{text}</p>
        {buttonText && link && (
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <Button className='btn btn-blue btn-renew-card'>
              {buttonText}
            </Button>
          </a>
        )}
      </div>
    </div>
  );

  const handleDataCardsRender = () => {
    const {
      amountAvailable,
      expirationDate,
      interestRate,
      amount: creditLimit,
    } = clientCreditInformation || {};

    if (
      clientCreditInformation &&
      Object.keys(clientCreditInformation).length > 0
    ) {
      const statusColour = creditStatusColour();
      const color = statusColour.toString();

      return (
        <div className='data-cards'>
          <CardDeck>
            <Card className='status-card-information'>
              <CardBody className='data-amount'>
                <div className='data-label'>{strings.mitCreditStatus}</div>
                <div className={`data-value ${color}`}>
                  {isActive &&
                    !isExpired &&
                    !dunning &&
                    !isAmountBelowZero &&
                    strings.mitCreditStatusActive}
                  {isActive &&
                    isExpired &&
                    !dunning &&
                    strings.mitCreditStatusExpired}
                  {isDisabled && strings.mitCreditStatusDisabled}
                  {isRejected && strings.mitCreditStatusRejected}
                  {(latePayment || isSuspended) &&
                    strings.mitCreditStatusClosed}
                  {dunning && isActive && strings.mitCreditStatusSuspended}
                  {isActive &&
                    !isExpired &&
                    !dunning &&
                    isAmountBelowZero &&
                    strings.mitCreditStatusFullyUtilized}

                  {/* Expiration card  */}

                  {isActive && !isExpired && !dunning && (
                    <span className='expiration-info'>
                      {strings.formatString(
                        strings.mitCreditExpiration,
                        moment(expirationDate, 'YYYY-MM-DD').format(
                          'DD-MM-YYYY'
                        )
                      )}
                    </span>
                  )}
                </div>
              </CardBody>
            </Card>
            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.mitCreditAvailable}</div>
                <div
                  className={`data-value ${
                    amountWithLineThrough ? 'suspended' : ''
                  } ${isAmountBelowZero ? 'amountZero' : ''}`}
                >
                  {!latePayment &&
                  !isDisabled &&
                  !isRejected &&
                  !isSuspended &&
                  !isAmountBelowZero
                    ? currencyFormatDA(amountAvailable || 0, true, true)
                    : '0 kr.'}
                </div>
              </CardBody>
            </Card>
            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.mitInterestRate}</div>
                <div className='data-value'>
                  {!latePayment && !isDisabled && !isRejected && !isSuspended
                    ? formatInterestRate(interestRate)
                    : '-'}
                </div>
              </CardBody>
            </Card>

            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.mitCreditLimit}</div>
                <div className='data-value'>
                  {!latePayment && !isDisabled && !isRejected && !isSuspended
                    ? `${formatAmount(creditLimit)} kr.`
                    : '0 kr.'}
                </div>
              </CardBody>
            </Card>
          </CardDeck>
        </div>
      );
    } else return null;
  };

  return (
    <>
      {renderWarningCard()}

      <Card className='custom-card'>
        <CardBody>{handleDataCardsRender()}</CardBody>

        {isActive && !isExpired && !dunning && (
          <CardBody className='credit-info-card pt-0'>
            <div className='title'>{strings.mitCreditInfoTitle}</div>
            <div className='content'>
              <p>{strings.mitCreditInfoP1}</p>
              <p>{strings.mitCreditInfoP2}</p>
            </div>
            <div className='button-actions'>
              <a
                href={`https://${process.env.REACT_APP_ONBOARDING_DOMAIN}/?tag=forny&companyId=X001&leadUuid=${uuid}&creditExpirationDate=${clientCreditInformation.expirationDate}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button className='btn btn-blue btn-credit-info'>
                  <i className='lnil lnil-bolt' />
                  {strings.mitStartCheckCreditButton}
                </Button>
              </a>
            </div>
          </CardBody>
        )}
        {isSuspended && !dunning && (
          <CardBody className='credit-info-card pt-0'>
            <div className='title'>{strings.mitCreditInfoTitle}</div>
            <div className='content'>
              <p>{strings.mitCreditInfoSuspended}</p>
            </div>
          </CardBody>
        )}
        {dunning && (
          <CardBody className='credit-info-card pt-0'>
            <div className='title'>{strings.mitCreditInfoTitle}</div>
            <div className='content'>
              <p>
                {strings.mitCreditInfoDunningP1}
                <br />
                {strings.formatString(
                  strings.mitCreditInfoDunningP2,
                  <a
                    className='color'
                    // href={config.termsAndConditionsWithoutBnpl}
                    href='https://sundhedplus.dk/assets/aftalebestemmelser.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {strings.mitCreditInfoDunningCreditAgreement}
                  </a>
                )}
              </p>
            </div>
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default SundhedCreditInfo;
