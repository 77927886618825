import {
  brandConfig,
  creditMaxAmountApproveCreditForWarningCards,
} from '../../config';

/* eslint-disable max-len */
const da = {
  // Login and menus
  username: 'E-mail',
  password: 'Adgangskode',
  login: 'Log ind',
  invalidPassword1: 'Ugyldigt brugernavn eller adgangskode.',
  invalidPassword2: 'Prøv venligst igen.',
  noAuthorityMsg:
    'Du har ikke rettigheder til det, du er i gang med. Kontakt venligst kundeservice for at få problemet rettet.',
  profile: 'Profil',
  logout: 'Log ud',
  settings: 'Indstillinger',
  settingsHeadline1: 'Brugerindstillinger',
  settingsHeadline1Link1: 'Din profil',
  settingsHeadline1Link2: 'Brugere',
  settingsHeadline2: 'Kontoindstillinger',
  settingsHeadline2Link1: 'Virkomhendes stamdata',
  settingsHeadline2Link2: 'Filialer',
  settingsHeadline2Link2Single: 'Filial',
  settingsHeadline3: 'Marketing og integrationer',
  settingsHeadline3Link1: 'SmartLink widget',
  settingsHeadline3Link2: 'Annoncér',
  help: 'Hjælp',
  loginHeadline: 'Log ind i Partnerportalen',
  forgotPassword: 'Glemt adgangskoden?',
  forgotPasswordHeadline: 'Nulstil din adgangskode',
  forgotPasswordParagraph:
    'Indtast venligst din e-mail. Så sender vi dig et link, så du kan ændre din adgangskode.',
  enterUsernameHeadline:
    'Indtast venligst din e-mail, så sender vi et link til dig, så du kan ændre adgangskoden',
  submitForm: 'Nulstil',
  successSubmit: 'Der er nu sendt en e-mail til brugeren: ',
  returnToLogin: 'Tilbage til login',
  validUsername: 'Indtast venligst et gyldigt brugernavn',
  loginHelp: 'Har du brug for hjælp?',
  loginAssistance: 'Har du brug for hjælp?',
  loggedInAs: 'Logget ind som',
  tooManyLoginAttempts: 'For mange loginforsøg.',
  waitToRetry:
    'Af sikkerhedsmæssige årsager, er du midlertidigt blokeret for yderligere loginforsøg. Vent venligst, eller {0}',
  resetPasswordToUnlock: 'nulstil din adgangskode.',
  loginWrongBrand: 'Prøv venligst at logge på {0}',
  loginMessageWithNewLink:
    'An email has been sent to {0} with a link to reset your password.',
  loginServerDownMessage:
    'Der lader til at være problemer med forbindelsen til serveren',
  sessionExpiredHeadline: 'Din session er udløbet',
  sessionExpiredText:
    'Du har desværre været inaktiv for længe. Af sikkerhedsmæssige årsager bedes du logge ind igen.',
  loginErrorNotCooperating: `Jeres klinik har sagt "Nej tak" til at samarbejde med ${brandConfig.appName}, og derfor er det pt ikke muligt at logge ind. Kontakt venligst kundeservice, hvis dette er en fejl.`,
  loginForMembersTitle: 'For Medlemmer',
  loginForMembersText:
    'Håndter dit Sundhed+ medlemskab i Medlemsportalen. Her kan du blandt andet redigere din medlemsprofil og administrere din Sundhed+ kredit.',
  loginForMembersButton: 'Log ind som medlem',
  loginForPartnersTitle: 'For Partnere',
  loginForPartnersText:
    'I Partnerportalen kan du, som behandler, håndtere de patienter der ønsker at betale for deres behandling med en Sundhed+ Kredit.',
  loginForPartnerButton: 'Log ind som partner',
  // reset password
  title: 'Skift adgangskode',
  headline:
    'Adgangskoden skal være på minimum 8 tegn og skal bestå af både store og små bogstaver og tal.',
  successTitle: 'Succes!',
  sucessMessage: 'Din adgangskode er blevet opdateret.',
  errorTitle: 'Error',
  errorMessage: 'Adgangskoden kunne ikke opdateres.',
  goToLogin: 'Gå til log ind',
  passwordStrength:
    'Adgangskoden skal være på mindst {0} tegn og skal bestå af tal og både store og små bogstaver.',
  newPassword: 'Ny adgangskode',
  repeatPassword: 'Bekræft ny adgangskode',
  changePasswordButton: 'Skift Adgangskode',
  passwordDontMatch: 'De indtastede adgangskoder er ikke ens',

  // Data validation
  required: 'Påkrævet',
  numberOfCharacters: 'Skal være {0} tegn',
  numberOfCharactersMax: 'Max {0} tegn',
  onlyLetters: 'Kun bogstaver',
  onlyDigits: 'Kun cifre',
  format: 'Format skal være d.ddd,dd',
  invalidEmail: 'Ugyldig e-mailadresse',
  invalidURL: 'Ugyldig URL',
  incompleteSelection: 'Venligst vælg mindst en kompetence',
  mustBeBigger: 'Skal være lig med eller større end',
  mustBeSmaller: 'Skal være mindre end',
  mustBeAtLeast: 'Skal være mindst ,{0} tegn.',
  mustContainNumber: 'Adgangskoden skal indeholde tal.',
  mustContainLetter: 'Adgangskoden skal indeholde bogstaver.',
  onlyAlphanumeric: 'Må kun indeholde bogstaver og cifre.',
  danishNumber: 'Indtast venligst et dansk nummer',
  minLoanAmount: 'Beløbet skal være minimum {0} kr.',
  maxLoanAmount: 'Beløbet kan ikke være højere end {0} kr.',
  decimalsError: 'Skal indeholde maksimalt 2 decimaler.',
  moreCommas: 'Kun ét komma tilladt.',
  inputEmpty: 'Feltet er tomt',
  requiredAllFieldsFilled:
    '*Vær venlig at sikre, at alle påkrævede felter er udfyldt',
  saving: 'Gemmer...',
  saved: 'Gemt',
  notSaved: 'Kunne ikke gemmes',

  leadPlaceholder: 'henvisning',
  leadsPlaceholder: 'henvisninger',
  clientsPlaceholder: 'patienter',
  companyPlaceholder: 'behandler',
  companiesPlaceholder: 'filialer',
  companiesDownload: 'Download',
  signupFeePlaceholder: 'Evt. omlægningsgebyr',

  // leads list
  searchPlaceholder: 'Søg efter {0}...',
  noItems: 'Så snart du modtager din første {0}, vil den blive synlig her...',
  noCategoryItems: 'Der er ingen {0} som matcher den valgte status...',
  tipCategoryTitle: 'Tip',
  patientsLabel: 'patienter',
  tipCategory:
    ': Husk at ændre status på dine {0}. Så kan du nemt fastholde overblikket over hvor langt de enkelte {1} er i forløbet.',
  errorLoading:
    'Der opstod en fejl under indlæsningen af siden. Prøv venligst igen.',
  searchEmpty: 'Ingen resultater fundet.',
  leadsDisplayFromCompany: 'Der vises kun henvisninger fra {0}.',
  leadsDisplayReset: 'Nulstil visning',

  // go back to list
  goToClientsList: 'Alle patienter',
  goToClinicsList: 'Alle filialer',
  goToChainsList: 'Alle behandlere',

  // lead details
  leadPersonalDataTitle: 'Patientoplysninger',
  leadPersonalDataSubtitlePhone:
    'Patienten foretrækker at blive kontaktet via telefon',
  leadPersonalDataSubtitleEmail:
    'Patienten foretrækker at blive kontaktet via email',
  noNote: '- Ingen noter -',
  requiresActionAlertTitle:
    'Denne henvisning er mere end 5 timer gammel og patienten er sikkert utålmodig efter at høre fra dig.',
  requiresActionAlertSubtitle:
    'Du bør kontakte patienten med det samme, hvis ikke I allerede har haft kontakt. Husk at skifte status på henvisningen for at undgå beskeder som denne. ',
  internalNote: 'Interne noter',
  saveInternalNote: 'Gem note',
  placeholderAddress: 'Indtast adresse',
  placeholderZipCode: 'Indtast postnummer',
  placeholderCity: 'Indtast by',

  // Credit details
  creditDetailsHeading: 'Kreditdetaljer',
  creditDetailsCreatedDate: 'Oprettelsesdato',
  creditDetailsNumber: 'Kreditnr.',
  creditDetailsLatestEval: 'Seneste kreditvur.',
  creditDetailsLatestChange: 'Seneste ændring',
  creditSendUpdate: 'Send email med opdateret kreditaftale',

  // credit details edit
  creditDetailsEdit: 'Rediger kredit',
  creditDetailsAmount: 'Kreditbeløb',
  creditDetailsInterestRate: 'Rentesats',
  creditDetailsExpirationDate: 'Udløbsdato',
  creditDetailsStatus: 'Kreditstatus',
  creditDetailsChangeReason: 'Begrundelse for ændring',
  creditDetailsEstimatedDisposableAmount: 'Beløb til fri disposition',
  creditDetailsMinDuration: 'Min. løbetid ved fuld udnyttelse:',
  creditDetailsMinDurationMonths: 'mdr.',
  creditDetailsMaxMotnhlyPayment: 'Maksimal månedlig ydelse:',
  creditDetailsValidateValuesError:
    'Kredittager skal som minimum have et månedligt beløb til fri disposition på {0} for at kunne tildeles en kredit på {1}',
  creditDetailsMinDurationcalculationError:
    'Den påkrævede minimumsløbetid for en max månedlig ydelse på {0} er mere end {1} måneder, hvilket er den øvre grænse. Sænk venligst kreditbeløbet eller øg max månedlig ydelse, f.eks ved at øge det estimerede rådighedsbeløb.',
  creditDetailsAmountError: 'Maks. {0} kr.',
  creditDetailsInterestRateError: 'Maks. {0} %',
  creditDetailsMinDurationError: 'Min {0} - Max {1}',
  creditDetailsMaxPaymentLimit: 'Max {0}',
  creditDetailsDateError: 'Maks {0} måneder fra i dag',
  creditDetailsCommentError: 'Angiv venligst en begrundelse',
  creditDetailsSaveButton: 'Gem ændringer',
  creditDetailsSaveButtonSuccess: 'Gemt',
  creditDetailsSaveButtonFailure: 'Ikke gemt',

  // company details
  companyDataTitle: 'Behandlerkoplysninger',
  filialStatus: 'Filialstatus',
  accountNo: 'Kontonr',
  createDate: 'Oprettet d.',
  lastLoginDate: 'Sidst logget ind d.',
  seeLeadsFor: 'Se alle tilknyttede patienter',
  companyName: 'Navn på behandler',
  account: 'Regnr. og kontonr.',
  noPhone: '- Ingen telefon -',
  noEmail: '- Ingen e-mail -',
  noWebsite: '- Ingen website -',
  noAccountNo: '- Ingen kontonr -',
  companyAffiliation: 'Tilknyttet til',
  clinicIndexTab1: 'Profil',
  clinicIndexTab2: 'Bookinglink',
  companyMasterDataHeading: 'Stamdata',
  companyMasterDataExtra: 'Filialnr:',
  companyMasterDataInfoText: `Kontakt venligst kundeservice på tlf. ${brandConfig.contactPhoneHtmlFormated} hvis du ønsker at ændre cvr eller kontonr`,
  companyProfileHeading: 'Profil i behandlerindeks',
  companyProfileUsage:
    'Behandlerindekset på {0} er Danmarks mest komplette indeks over sundhedsbehandlere. Som Sundhed+ Partner har I mulighed for at tilpasse jeres {1} i behandlerindekset, og dermed opnå optimal eksponering.',
  companyProfileUsageSiteText: 'sundhedplus.dk',
  companyProfileUsageProfileText: 'profil',
  companyProfileStatus: 'Profil status',
  companySeeProfileBtn: 'Se profilen',
  companyActivateShowInIndexBtn: 'Synlig',
  companyDeactivateShowInIndexBtn: 'Skjult',
  companyProfileSaveBtn: 'Gem ændringer',
  companyProfilePhone: 'Telefon',
  companyProfileURL: 'URL',
  companyProfileEmail: 'E-mail',
  setDisabledBookingLink:
    'Bemærk venligst, at bookingforespørgsler er deaktiveret, da det kræver en e-mail adresse.',
  setEnabledBookingLink:
    'Bemærk venligst, at det nu igen er muligt at slå bookingforespørgsler til.',
  companyProfileDescription: 'Beskrivelse',
  companyProfileDescriptionHint:
    'Skriv en kort tekst der vil fremgå af filialens profil i behandlerindeket.',
  companyProfileDescriptionHintCounter: 'tegn',
  companyProfileCategories: 'Kompetencer',
  companyProfileLogo: 'Logo',
  companyProfileTipHeadline: 'Tip til jeres profil',
  companyProfileTipParagraph1:
    'Alle danske sundhedsbehandlere er oprettet med en basisprofil i behandlerindekset på {0}',
  companyProfileTipParagraph2:
    'Som {0} Partner har I mulighed for at udbygge jeres profil med fx profiltekst og logo, og dermed fremstå mere attraktive overfor brugerne.',
  companyProfileTipParagraph3:
    'Desto mere komplet jeres profil fremstår, desto flere nye patienter kan I forvente at få via behandlerindekset.',
  companyBookingLinkHeadline: 'Bookinglink',
  companyBookingLinkUsage:
    'Vælg hvad der skal ske når der klikkes på knappen “Book tid” på jeres behandlerprofil',
  companyProfileBookingLink: 'Bookinglink',
  companyBookingLinkOption1Label: 'Bookingforespørgsel',
  companyBookingLinkOption1Text:
    'Brugeren får vist en formular til at sende en bookingforespørgsel med ønsker til tider og behandling. Hver gang en bruger laver en bookingforespørgsel, modtager I en emailnotifikation med et link til at se selve bookingforespørgslen.',
  companyBookingLinkOption2Label: 'Egen onlinebooking',
  companyBookingLinkOption2Text:
    'Brugeren sendes videre til jeres eget onlinebookingsystem.',
  companyBookingLinkOption3Label: 'Kun direkte henvendelser ',
  companyBookingLinkOption3Text:
    'Kunden får vist en besked med en opfordring til at kontakte jer direkte på telefon eller email.',

  deleteCompanyConfirmMessage:
    'Er du sikker på, at du ønsker at slette filialen, {0}?',
  deleteCompanyWarningHeadline: 'Vær venligst opmærksom!',
  deleteCompanyWarningBody:
    'Det er ikke muligt at slette en aktiv filial. {0} Sæt venligst filialen passiv eller inaktiv før, den slettes.',
  deleteCompanyChainWarningBody1:
    'Det er ikke muligt at slette en aktiv behandler. {0} Sæt venligst behandleren passiv eller inaktiv før, den slettes.',
  deleteCompanyChainWarningBody2:
    'Det er ikke muligt at slette en behandler med aktive filialer. {0} Sæt venligst alle filialer passive eller inaktive før, behandleren slettes.',
  companyNameShort: 'Firmanavnet er for kort',
  companyNotCooperatingWarningHeadline: 'Kontoen er inaktiv',
  companyNotCooperatingWarningBody1: `Da I i øjeblikket ikke samarbejder med ${brandConfig.appName}, har I ikke mulighed for at tilføje nye patienter eller registrere nye regninger.{0}
  I har dog stadig adgang til at se betalingsstatus på tidligere registrerede regninger.`,
  companyNotCooperatingWarningBody2:
    'Kontakt kundeservice på {0} eller tlf. {1}, hvis I ønsker at få genaktiveret kontoen.{2}',

  noCompanyForChainHeadline: '{0} har ikke associerede filialer',
  noCompanyForChainText:
    '{0} har ikke flere associerede filialer. Vil du slette behandler?',
  noCompanyForChainBtn1: 'Slet',
  noCompanyForChainBtn2: 'Ignorer',

  setCompanyChainActiveMessage:
    'Bemærk venligst, at den relaterede behandler nu også er aktiveret.',
  setCompanyChainActiveLink: 'Gå til behandler',
  setCompanyChainActiveBtn: 'OK',

  // credit agreement
  creditAgreementTitle: 'Kreditaftale',
  invoiceHistoryTitle: 'Regningsbeløb',
  validTimeLabel: 'Gyldig til',
  expirationTimeLabel: 'udløber om',
  expirationTimeToday: 'Udløber i dag',
  expiredTimeLabel: 'Udløbet d.',
  creditLine: 'Kreditramme:',
  remainingAmount: 'Kredit til rådighed:',
  noCreditAgreement: '- Ingen kreditaftale -',
  noAmount: 'kr. 0,00',
  createActionButton: 'Opret',
  editActionButton: 'Ret',
  undoActionButton: 'Fortryd',
  saveActionButton: 'Gem',

  // lead associated clinics
  clinicAccess: 'Klinikadgang',
  clinicDate: 'Dato',
  clinicAssociated: 'Behandler',
  removeAccess: 'Fjern adgang',
  removeAccessConfirmation:
    'Bekræft venligst at du ønsker at fjerne {0}s adgang til {1}s kredit',
  addclinic: 'Tilknyt klinik',

  // credit informations
  creditActiv: 'Kreditten er aktiv',
  expresMore: 'Udløber om {0} dage ',
  expiresOne: 'Expires in 1 dag',
  expiresTuday: 'Udløber i dag',
  availableCreditAmunt: 'Kreditbeløb til rådighed',
  expired: 'Udløbet',
  creditNeedsRenewel: 'Kreditten skal fornys før den kan benyttes',
  noActivCredit: 'Ingen aktiv kredit',
  creditTerminated: 'Patientens kredit er lukket',
  applicationDenied: 'Ansøgning afvist',
  patientWitNoCredit: 'Patienten har ingen aktiv kredit',
  noCreditFacility: 'Ingen credit',
  patientWithNocreditAplication: 'Patienten har ikke ansøgt om en kredit',
  patientWithNocreditFacility: 'Patienten har endnu ingen kredit',
  noDentiCredit: `Patienten har endnu ikke ansøgt om en ${brandConfig.appName} Kredit.`,
  helpPatient:
    'Hjælp patienten med at ansøge, ved at sende patienten et ansøgningslink. Ansøgningsprocessen tager under 2 minutter, og patienten får svar med det samme.',
  sendAppLink: 'Send ansøgningslink',
  creditSuspended: 'Kreditten er suspenderet',
  creditHasExpired: 'Kreditten er udløbet',
  latepayment: 'Sendt til inkasso',
  // Grant access to the patient's credit facility.
  assignClinic: 'Tilknyt behandler',
  selectPatient: "Angiv en behandler som skal have adgang til {0}'s kredit.",
  selectPatientNotePart1:
    'Når du tilknytter behandleren, vil patienten modtage en sms-notifikation om at behandleren har fået adgang til patientens kredit.',
  selectPatientNotePart2:
    'Behandleren vil ligeledes modtage en e-mailnotifikation om at de nu har adgang til kreditten.',
  assignGrantedClinic: 'Tilknyt',
  accesGranted: 'Behandler tilknyttet',
  accesGrantedTo: '{0} har nu fået adgang til {1}s kredit.',
  accessGrantedNotification:
    'Både patienten og behandleren er blevet underrettet.',
  accesFaildTo: 'Det lykkedes ikke at tilknytte {0} til {1}s kredit',
  accesFaild: 'Noget gik galt...',
  accesFaildReason: 'Fejlkode: ',
  accessReasignClinicHeadline: 'Patienten er allerede tilknyttet til klinikken',
  accessReasignClinicText:
    '	Patienten, {0}, er allerede tilknyttet til klinikken, {1}.',
  accessReasignClinicNote: '{0} Patient vil nu blive genindlæst',
  accessReasignClinicNoteBold: 'NOTE:',
  accessReasignClinicButton: 'Genindlæs patienten',

  // statuses
  VIEW_ALL: 'Vis alle',
  REQUIRES_ACTION: 'Kræver handling',
  UNTREATED: 'Ubehandlet',
  CLIENT_MAKES_CONTACT: 'Kunden tager kontakt',
  TRIED_CONTACTED: 'Forsøgt kontaktet',
  HAS_BOOKED_TIME: 'Har booket en tid',
  ONGOING_TREATMENT: 'Igangværende behandling',
  COMPLETED_WITH_TREATMENT: 'Afsluttet med behandling',
  COMPLETED_WITHOUT_TREATMENT: 'Afsluttet uden behandling',
  SMARTLINK: 'SmartLink',
  ACTIVE: 'Partner',
  DEACTIVE: 'Passiv',
  PASSIVE: '(Nyoprettet)',
  PASSIVE_OUT_OF_COMPONENT_STATUS: 'Nyoprettet',
  DELETED: 'Slettet',
  DEPRECATED: 'Forældet',
  PAID_OUT: 'Afregnet',
  BLOCKED: 'Spærret',
  SUSPENDED: 'Suspenderet',
  CLOSED: 'Lukket',
  REJECTED: 'Passiv',
  // toast
  // success
  invoiceAcceptTitle: 'Regningen er godkendt',
  invoiceAcceptMessage: '{0} har godkendt en regning på {1}',
  labelAccept: 'Klik her for at se patienten',
  statusSuccessTitle: 'Status er opdateret',
  successStatusChange1: 'Status for patient',
  successStatusChange2: 'er blevet opdateret.',
  successStatusChange3: 'Status for filialen',
  successStatusChange4: 'Status for behandler',
  successTextMessageSent: 'En sms er sendt til ',
  resendSMSTitleSuccess: "SMS'en er afsendt",
  successEmailSent: 'E-mail blev sendt til: ',
  resendEmailTitleSuccess: 'E-mail sendt',
  successPatientChange1: 'Stamdataene for ',
  successPatientChange2: 'er blevet opdateret.',
  patientSuccessTitle: 'Patienten er opdateret',
  patientInternalNoteSuccessMessage: 'Den interne note er opdateret',
  patientInternalNoteErrorMessage: 'Ændringen kunne ikke gemmes',
  invoiceCreateSuccessTitle: 'Regningen er oprettet',
  invoiceCreateSuccessMessage:
    '{0} har netop modtaget et godkendelseslink via SMS og e-mail. Beløbet på {1} kr. udbetales når {2} har godkendt regningen.',
  editCompanySuccess: 'Klinikoplysningerne for {0} er blevet opdateret',
  editCompanySuccessTitle: 'Ændringerne blev gemt',
  clinicInternalNoteSuccessMessage: 'Den interne note er opdateret',
  clinicInternalNoteErrorMessage: 'Den interne note kunne ikke opdateres',
  editCompanyChainSuccess: 'Behandleroplysningerne for {0} er blevet opdateret',
  editCompanyChainSuccessTitle: 'Ændringerne blev gemt',
  companyChainInternalNoteSuccessMessage: 'Den interne note er opdateret',
  companyChainInternalNoteErrorMessage: 'Den interne note kunne ikke opdateres',
  successTextDelete: 'Regningen med nr. {0} er blevet slettet.',
  deleteTitleSuccess: 'Regning slettet',
  successSettleTitle: 'Lånet blev indfriet',
  successSettleText: 'Lånet med lånenummer {0} blev indfriet.',

  // error
  invoiceNotAcceptTitle: 'Regning afvist',
  invoiceNotAcceptMessage: '{0} har afvist en regning',
  statusErrorTitle: 'Statusen kunne ikke opdateres',
  errorStatusChange1: 'Status for patient',
  errorStatusChange2: 'kunne ikke opdateres.',
  errorStatusChange3: 'Status for filialen',
  errorStatusChange4: 'Status for behandler',
  errorTextMessageSent: 'SMS kunne ikke sendes',
  resendSMSTitleError: 'SMS ikke sendt',
  errorEmailSent: 'E-mail kunne ikke sendes',
  resendEmailTitleError: 'E-mail ikke sendt',
  patientErrorTitle: 'Ændringerne kunne ikke gemmes',
  errorPatientChange: 'Patienten kunne ikke opdateres.',
  invoiceCreateErrorTitle: 'Regning blev ikke oprettet',
  invoiceCreateErrorMessage: ' kunne ikke oprettes.',
  invoiceCreateError: 'Regning kunne ikke oprettes.',
  invoiceAmountExcedeedTitle: 'Regning kunne ikke oprettes',
  invoiceAmountExcedeedMessage:
    'Det angivne beløb overstiger kredittens restsum.',
  errorEditCompany: 'Klinikken kunne ikke opdateres.',
  errorEditCompanyTitle: 'Ændringerne blev ikke gemt',
  errorEditCompanyChain: 'Behandlere kunne ikke opdateres.',
  errorEditCompanyChainTitle: 'Ændringerne blev ikke gemt',
  errorTextDelete: 'Regningen med nr. {0} kunne ikke slettes.',
  deleteTitleError: 'Regning ikke slettet',
  errorSettleTitle: 'Lånet kunne ikke indfries',
  errorSettleText: 'Lånet med lånenummer {0} kunne ikke indfries.',

  // Credit Agreement Page
  createAgreementPageTitle: 'Opret kredit',
  debtCollectionPageTitle: 'Inkassosager',
  createAgreementPageTextFirst:
    brandConfig.appName === 'Denti'
      ? 'Har du en patient som ønsker at ansøge om en Denti Kredit?'
      : 'Har du en patient som ønsker at blive medlem af Sundhed+?',
  createAgreementPageTextSecond:
    'Så kan du herunder hurtigt og nemt sende patienten en SMS eller e-mail med et ansøgningslink. Ansøgningsprocessen tager ikke mere end 2 minutter og patienten skal ikke bruge andet end sit NemID for at identificere sig.',
  // createAgreementPageTextThird: 'Oprettelsesprocessen tager under 2 minutter og patienten skal ikke bruge andet end sit NemD for at blive valideret.',
  smsTextTitle:
    brandConfig.appName === 'Denti'
      ? '{0} samarbejder med Denti.dk om at give dig markedets bedste betalingsordning'
      : '{0} samarbejder med Sundhed+ om at give dig markedets bedste betalingsordning til sundhedsydelser',
  smsTextHeader:
    brandConfig.appName === 'Denti'
      ? 'Få dit uforpligtende tilbud her:'
      : 'Ansøg om et medlemskab af Sundhed+ her:',
  smsTextListItem:
    ' - det tager under 2 minutter og kræver ikke andet end dit NemID.',
  smsTextClosing:
    brandConfig.appName === 'Denti'
      ? 'Tilbuddet er gratis og uforpligtende.'
      : 'Medlemskabet er gratis og uforpligtende.',
  smsTextRegards: 'Med venlig hilsen',
  smsTextSender: `Team ${brandConfig.appName}`,
  smsText1: brandConfig.appName
    ? 'Klik på dette link for at få markedets bedste tandkredit: '
    : 'Bliv medlem her: ',
  smsText2: '. Husk du skal bruge dit NemID.',
  emailTemplateTextTitle: 'Skal vi dele din regning op i mindre bidder?',
  emailTemplateTextSubtitle:
    brandConfig.appName === 'Denti'
      ? '{0} samarbejder med Denti.dk om at give dig markedets bedste betalingsordning.'
      : '{0} samarbejder med Sundhed+ om at give dig markedets bedste betalingsordning til sundhedsydelser.',
  emailTemplateTextDescription1:
    brandConfig.appName === 'Denti'
      ? 'Klik på linket herover for at få et uforpligtende kredittilbud - det tager under 2 minutter og kræver ikke andet end dit NemID.'
      : 'Klik på linket herover for at ansøge om et gratis medlemskab - det tager under 2 minutter og kræver ikke andet end dit NemID.',
  emailTemplateTextDescription2:
    brandConfig.appName === 'Denti'
      ? 'Du binder dig ikke til noget, og betalingsordningen bliver først aktiveret i det øjeblik du anmoder din tandlæge om at en regning skal betales via Denti.'
      : 'Du binder dig ikke til noget, og hvis du bliver godkendt til en Sundhed+ Kredit, er det helt op til dig om du ønsker at benytte den',
  emailTemplateTextCopyright: '2020 CMC Finans ApS',
  emailTemplateTextAddress:
    'Charlottenlund Stationsplads 2, 2920 Charlottenlund',
  clickHere: 'Klik her:',
  smsLink: brandConfig.presentationSite,
  signatureText: 'Med venlig hilsen',
  signatureName: 'Tandlægerne på Østerbro',
  telLabel: 'Telefon',
  telPlaceholder: 'Indtast telefonnr.',
  submitText: 'Send SMS',
  SMSTabText: 'SMS',
  EmailTabText: 'E-mail',
  emailClickHere: 'Klik her:',
  emailLink: `${brandConfig.presentationSite}/opret-aftale`,
  signatureTextEmail: "Med venlig hilsen'",
  signatureNameEmail: 'Tandlægerne på Østerbro',
  emailLabel: 'E-mail',
  emailPlaceholder: 'Indtast e-mail',
  submitTextEmail: 'Send e-mail',
  month: 'måned',
  months: 'måneder',
  monthsShort: 'mdr.',
  days: 'dage',
  day: 'dag',
  minutes: 'min',
  hours: 'timer',
  hour: 'time',
  seconds: 'sekunder',
  second: 'sekund',
  ago: 'siden',

  // menu
  leads: 'Henvisninger',
  companies: 'Filialer',

  // Guides
  forgotPhone: 'Min klient har glemt sin mobiltelefon - hvad gør jeg nu?',
  loanOfferHowTo: 'Min klient vil lave en låneansøgning - hvordan gør man?',
  invoiceHowTo: `Jeg vil registrere en regning - så den kan blive betalt af ${brandConfig.appName}.`,

  // Create Credit modal
  createCreditModalTitle:
    brandConfig.appName === 'Denti' ? 'Opret kredit' : 'Nyt medlem',

  // Edit Patient Form
  firstNameLabel: 'Fornavn',
  lastNameLabel: 'Efternavn(e)',
  addressLabel: 'Adresse',
  zipLabel: 'Postnr.',
  cityLabel: 'by',
  noteLabel: 'noter',
  phoneLabel: 'telefon',
  cprLabel: 'cpr',
  websiteLabel: 'website',
  emailLabelEdit: 'E-mail',
  notePlaceholder: 'Skriv en note...',
  cprNumberLabel: 'CPR Nummer',
  cpr: 'CPR nr.',

  // invoice
  invoiceDate: 'Dato',
  invoiceLead: 'Reference',
  invoiceAction: 'Handlinger',
  invoiceStatus: 'Status',
  invoiceTotal: 'Beløb',
  invoiceClinic: 'Filial',
  registerInvoice: 'Registrér beløb',
  registerActionButton: 'Registrér',
  leadInvoiceIdPlaceholder: 'Indtast ref.',
  leadInvoiceDetailsMessage: 'Drej telefonen for at registrere en ny regning',
  leadExpiredTooltipMessage:
    'Der kan ikke registreres yderligere beløb, da kreditten er udløbet eller den resterende kredit er mindre end {0} kr.',
  observation:
    'OBS: Da patienten har valgt en rentefri kredit, er det kun muligt at registrere ét beløb.',
  observationExp: 'Kreditten er udløbet. Patienten opfordres til at ',
  reapply: 'ansøge på ny.',
  obs1: `Da den tilbageværende kredit er mindre end 500 kr., er det ikke muligt at tilføje yderligere beløb. ${
    brandConfig.appName === 'Denti'
      ? 'Patienten kan kontakte Denti på tlf. 22 22 11 21 for at forespørge på en forhøjelse af kreditten.'
      : ''
  }`,
  obs2: 'Da den tilbageværende kredit er mindre end 500 kr., er det ikke muligt at tilføje yderligere beløb.',
  obs3: 'Kreditten er udløbet. Patienten opfordres til at ',
  obs4: 'Kreditten er udløbet.',
  obs4Link: 'Send patienten et link til at forny kreditten.',
  obsZeroCredit:
    'Kreditten er i øjeblikket fuldt udnyttet. Der kan derfor ikke registreres yderligere regningsbeløb på nuværende tidspunkt.',

  suspensionText: `Kreditten er suspenderet. Kontakt ${brandConfig.appName} for yderligere info, eller hjælp patienten med at {0}`,
  suspensionTextNoInvoice: `Kreditten er suspenderet. Kontakt ${brandConfig.appName} for yderligere info.`,
  suspensionLinkText: 'opdatere sit betalingskort',
  suspensionTooltip:
    'Der kan i øjeblikket ikke registreres yderligere beløb, da kreditten er suspenderet.',
  resendText:
    'Kreditten er spærret for registrering af yderligere regningsbeløb indtil patienten har godkendt/afvist den senest indtastede regning.',
  resendTextOther:
    'En anden behandler har registreret en regning som afventer patientens godkendelse/afvisning. Det er ikke muligt at registrere yderligere regninger indtil da.',
  resendSMSLinkText: 'Gensend sms med godkendelseslink til patienten',

  createdByText: '- Oprettet af anden behandler -',

  referredDenti:
    brandConfig.appName === 'Denti'
      ? 'Henvist fra Denti.dk'
      : 'Henvist af Sundhed+',
  referredDentiTooltip: `Mener du ikke, at ${brandConfig.appName} har henvist denne kunde, kan du rette henvendelse til {0}`,

  // payment agreement table
  agreementLoanHeading: 'Betalingsaftaler',
  agreementLoanStartDate: 'Date',
  agreementLoanType: 'Type',
  agreementLoanStatus: 'Status',
  agreementLoanInterestRate: 'Rente',
  agreementLoanRemainingPrincipal: 'Restgæld',
  agreementLoanAmount: 'Lånebeløb',
  agreementRemainingAmount: 'Aktuel restgæld',
  agreementNormalLoan: 'Låneaftale',
  agreementZeroInterest: 'Delbetaling',

  // newInvoice
  // newInvoiceSuccess
  newInvoiceSuccessTitle: 'Regningsbeløbet er registreret',
  newInvoiceSuccessSubtitle: 'Nu sker der følgende',
  stepOne:
    'Patienten har netop modtaget en e-mail og en sms med et godkendelseslink.',
  stepTwo:
    'Patienten skal klikke på linket og bekræfte at han ønsker at betale regningsbeløbet på {0} kr via sin {1} Kredit.',
  stepThree:
    'I får besked når patienten har godkendt, og I vil herefter få udbetalt beløbet til jeres bankkonto indenfor 24 timer.',
  newInvoiceSuccessNote:
    'Vi opfordrer til at I altid får jeres patienter til at godkende regningsbeløbet inden de forlader klinikken. Det er jeres garanti for at I modtager betalingen.',

  // newInvoiceError
  newInvoiceErrorTitle: 'Noget gik galt',
  invoiceNotRegistered: 'Det lykkedes desværre ikke at registrere regningen.',
  newInvoiceErrorContactDetails: `Forsøg venligst igen, eller kontakt kundeservice på tlf. ${brandConfig.contactPhoneHtmlFormated} eller {0}, hvis problemet fortsætter.`,

  // newInvoiceForm
  newInvoiceFullAmount: 'Beløbet dækker en regning på udført arbejde',
  newInvoicePartialAmount: 'Beløbet er aconto eller et overslag',
  newInvoiceRegister: 'Registrér',
  newInvoiceTitle: 'Registrér regningsbeløb',
  newInvoicePaymentOptionHeadline: 'Beløbet udbetales til',
  newInvoicePaymentOption1: 'Behandler',
  newInvoicePaymentOption2: 'Patient',
  newInvoicePaymentReasonHeadline: 'Årsag til direkte udbetaling',
  newInvoicePaymentReasonPlaceholder:
    'Beskriv kort årsagen og tilføj link til dokumentation.',
  newInvoiceAmount: 'Regningsbeløb',
  newInvoiceAmountNote: 'Min. {1} - Max {0}',
  newInvoiceAmountNoteWithoutMin: 'Max {0}',
  newInvoiceReference: 'Reference',
  newInvoiceReferenceNote: 'Max 10 tegn. Vil fremgå på dit kontoudtog',
  newInvoiceGeneralError:
    'Tjek venligst at alle felter er udfyldt og at det indtastede beløb ikke overstiger patientens tilgængelige kredit.',
  loadingCompanies: 'Indlæser filialer...',

  // tooltips
  emailResendTooltip: 'Gensend e-mail',
  smsResendTooltip: 'Gensend sms',
  printInvoiceTooltip: 'Gem som PDF',
  loanDetailsTooltip: 'Aftaledetaljer',
  loanDetailsDraftTooltip: 'Aftaleudkast',
  referenceToolTip:
    brandConfig.appName === 'Denti'
      ? 'Dette er en selvvalgt reference. Det er denne tekst, der vil fremgå af dit kontoudtog, når pengene overføres fra Denti.'
      : 'Dette er en selvvalgt reference. Det er denne tekst, der vil fremgå af dit kontoudtog, når pengene udbetales af Sundhed+',
  latePaymentTooltip:
    brandConfig.appName === 'Denti'
      ? 'Denne patient er sendt til inkasso. Der kan ikke registreres yderligere regninger på kreditten.'
      : 'Der kan ikke registreres yderligere regninger på kreditten.',
  cloneTooltip: 'Klon regningen',
  deleteTooltip: 'Slet regningen',
  deletePendingTooltip: 'Annuller regning',
  settleTooltip: 'Indfri lån',

  // noLeadsDetails
  noLeadsQuestion: 'Brug for hjælp?',
  noLeadsTextFirst:
    'Vi sidder klar til at hjælpe dig alle hverdage fra kl. 10.00 til 13.00',
  noLeadsTextSecond:
    'Derudover besvarer vi så vidt muligt henvendelser på e-mail inden for ca. 60 minutter i tidsrummet fra kl. 13.00 til 16.00',

  // Payment plan
  paymentAgreement: 'Dette er en betalingsaftale mellem',
  and: 'og',
  productType: 'Produkttype',
  productTypeKredit: 'Kredit',
  agreementNumber: 'Aftalenummer',
  creditAmount: 'Kreditbeløb',
  duration: 'Løbetid',
  establishmentFee: 'Etableringsomkostninger',
  establishmentFeeShort: 'Etableringsomk.',
  annualInterest: 'Årlig renteprocent',
  apr: 'ÅOP',
  totalCreditCost: 'Samlede kreditomkostninger',
  monthlyPaymentsSum: 'Samlet tilbagebetalingsbeløb',
  startDate: 'Etableringsdato',
  endDate: 'Sidste betaling',
  contactDetails: 'Kontaktdetaljer',
  creditor: 'Kreditgiver',
  companyRegistration: 'CVR',
  debtor: 'Kredittager',
  paymentSummary: 'Betalingsoversigt',
  paymentSummaryStatus: 'Status for betaling',
  paymentInterest: 'Rente',
  paymentInstallment: 'Afdrag',
  payment: 'Ydelse',
  inTotal: 'I alt',
  total: 'Total',
  important: 'VIGTIGT',
  contractDetails1:
    'Hvis Kredittager ikke betaler sine månedlige ydelser rettidigt har Kreditgiver mulighed for at afslutte og opsige denne betalingsplan med øjeblikkelig virkning.',
  contractDetails2:
    'Kredittager erklærer at have læst og forstået kreditaftalen og tilhørende aftalebestemmelser, samt lever op til og accepterer de beskrevne vilkår, samt at de af kredittager afgivne oplysninger er korrekte.',
  contractNote1: 'Etableringsomkostningerne udgør 5% af lånebeløbet.',
  contractNote2:
    'Regningsbeløbet udbetales til behandleren indenfor 24 timer efter at regningen er godkendt af patienten.',
  // contractNote3: 'Første ydelse betales d. 1. i næstkommende måned hvis regningen godkendes før d. 20.',
  // contractNote4: 'Hvis efter d. 20 betales 1. ydelse ved løbende måned + 1 måned.',
  contractNote3: 'Første ydelse betales d. 1. i næstkommende måned.',
  contractNote4: ' ',
  signature: 'Underskrift',
  date: 'Dato',

  // agreement modal

  agreementModalTitle:
    'Ønsker du den indledende aftale{0} eller den nuværende statusaftale?',
  agreementModalNoSignatureData: '(ingen signaturdata fundet)',
  agreementModalInitial: 'Initial',
  agreementModalCurrent: 'Nuværende',

  //  Client information
  name: 'Navn',
  firstName: 'Fornavn',
  lastName: 'Efternavn',
  ssno: 'CPR-nummer',
  address: 'Adresse',
  phone: 'Telefonnummer',
  email: 'E-mail',
  zip: 'Postnummer',
  city: 'By',

  // Confirmation modal
  confirmActionSMS: 'Er du sikker på, at du vil sende en sms til ',
  confirmActionEmail: 'Er du sikker på, at du vil sende en e-mail til ',
  confirmActionDelete:
    'Er du sikker på at du ønsker at slette regningen på {0} kr. for {1}?',
  cancel: 'Fortryd',
  confirm: 'Bekræft',
  close: 'Luk',
  invoiceTitle: 'Bekræft...',
  deleteInvoiceTitle: 'Bekræft sletning',
  internalServerErrorTitle: 'Hov, noget gik galt!',
  internalServerErrorHeader:
    'Det ser ud til at der er opstået et teknisk problem.',
  internalServerErrorText:
    'Forsøg venligst igen, eller kontakt os på {0} eller tlf. {1} hvis problemet fortsætter',
  internalServerErrorFooter: 'Vi beklager ulejligheden!',
  settleConfirmationTitle: 'Bekræft indfrielse',
  settleConfirmationText:
    'Kontrolér at patienten har indbetalt restgælden på {0} før du indfrier lånet.',

  // Invoice status
  waitingForSignature: 'Afventer',
  accepted: 'Godkendt',
  notAccepted: 'Afvist af klient',
  active: 'Godkendt',
  deprecated: 'Udløbet',
  payedInFull: 'Betalt ud',
  latePayment: 'Inkasso',
  deleted: 'Slettet',
  settled: 'Indfriet',
  payed: 'Betalt',
  inactive: 'Inaktiv',
  debtCollection: 'Inkasso',
  restructured: 'Omlagt',
  consolidated: 'Konsolideret',
  updateCreditCard: 'Opdatér betalingskort',
  onBoarding: 'Onboarding',
  member: 'Medlem',
  cancelled: 'Udmeldt',
  activeCredit: 'Aktiv',
  expiredCredit: 'Udløbet',
  rejectedCredit: 'Afvist',
  suspended: 'Suspenderet',
  latePaymentCredit: 'Inkasso',
  noCredit: 'Ingen kredit',
  denti: 'Denti',
  sundhed: 'Sundhed+',

  // New invoice history statuses
  pendingStatus: 'Afventer godkendelse',
  approvedStatus: 'Godkendt',
  approvedStatusZeroInterest: 'Godkendt (delbetaling)',
  paidOutStatus: 'Afregnet',
  paidOutStatusZeroInterest: 'Afregnet (delbetaling)',
  declinedStatus: 'Afvist',
  seeReceiptKebabMenu: 'Se kvittering',

  // Payment agreement statuses
  loanActiveStatus: 'Aktiv',
  loanConsolidatedStatus: 'Konsolideret',
  loanRestructuredStatus: 'Omlagt',
  loanLatePaymentStatus: 'Inkasso',
  loanPayedStatus: 'Betalt',
  loanSettledStatus: 'Indfriet',

  // Change credit details
  heading: 'Opdatér betalingskort',
  subheading: 'Kreditnr. {0}',
  firstParagraph:
    'Når du har indtastet dine kortoplysninger og trykker "Gem", vil vores betalingspartner automatisk forsøge at trække 0 kr. på dit kort. Dette sker for at validere, at det indtastede betalingskort er aktivt.',
  secondParagraph:
    'Først når valideringen er gennemført, vil eventuelle udestående betalinger blive trukket på kortet.',

  invoiceListHeading: 'Dine aktive betalingsaftaler',
  line1: '{0}, {1} (Lånebeløb: {2} kr.).',
  line2: '{0}: {1} mdr., Sidste betaling: {2}. ',
  amountC: '{0} kr.',
  totalHeading: 'Samlet månedlig betaling:',
  contactHeading: 'Vores kundeservice sidder klar på tlf. {0} og {1}.',
  errorHeading: 'Aftalen blev ikke fundet',
  errorP1: 'Vi kan ikke finde den forespurgte aftale i vores system.',
  errorP2:
    'Det er sikkert os der har begået en fejl, så tøv ikke med at kontakte os på {0} eller tlf. {1} hvis problemet fortsætter.',

  // Debt collection page
  remainingPrincipal: 'Hovedstol',
  debtCollectionDate: 'Sendt til inkasso',
  interestInArreas: 'Morarente',
  collectionAgency: 'Inkassobureau',
  caseNumber: 'Sagsnr.',
  note: 'Note',
  initialLoanAmount: 'Lånebeløb',
  debtCollectionTitle: 'Debt collection loans',
  registerDebtLoanButton: 'Register debt loan',
  invoiceNo: 'Aftalenummer',
  loanInterestRate: 'Rentesats',
  loanCreatedDate: 'Etableringsdato',
  debtCreateSuccessMessage:
    'The loan with number: {0} was registered in debt collection.',
  debtCreateSuccessTitle: 'Loan registered in debt collection',
  loanCreateErrorMessage:
    'The loan could not be registered in debt collection!',
  loanCreateErrorTitle: 'Loan not registered in debt collection',

  // lead options tabs
  overviewTab: 'Oversigt',
  logTab: 'Historik',
  logTabDate: 'Dato',
  logTabEvent: 'Hændelse',
  logTabUser: 'Bruger',

  // loan details view
  loanDetails: 'Lånedetaljer',
  loanAmount: 'Lånebeløb',
  setupFee: 'Oprettelsesgebyr',
  interestRate: 'Rente/ÅOP',
  loanDuration: 'Løbetid',
  installments: 'Ydelser',
  paid: 'Betalt',
  paidReepay: 'Betalt (Kortbetaling)',
  paidManual: 'Betalt (Manuel)',
  overdue: 'Forfalden',
  paidInTotal: 'Betalt i alt',
  repayments: 'Heraf afdrag',
  interests: 'Heraf renter',
  additionalCosts: 'Heraf gebyrer',
  principalAsOf: 'Hovedstol pr. {0}',
  remainingDebt: 'Restgæld per {0}:',
  signatureModalTitle: 'Signaturdata er ikke tilgængelige',
  signatureModalBtn: 'Ok',

  // loan calculator
  calculatorTitle: 'Omtrent hvor meget koster behandlingen?',
  calculatorAmount: 'Beløb:',
  calculatorQuestion1: 'Hvor mange måneder vil du tilbagebetale over?',
  calculatorMonth: '{0} mdr.',
  calculatorMonthlyBenefit: 'Månedlig ydelse:',
  calculatorEstablishmentCosts: 'Betalingsgebyr (indregnet i ydelsen):',
  calculatorInterestRate: 'Debitorrente:',
  calculatorTotalCosts: 'Samlede kreditomkostninger:',
  calculatorAnnualCosts: 'Årlige omkostninger i procent (ÅOP):',
  calculatorTotalRepayment: 'Samlet tilbagebetaling:',
  calculatorNote:
    'Ovenstående eksempel tager udgangspunkt i en debitorrente på {0}. Når du ansøger om en kredit, udarbejdes et personligt kredittilbud med en kreditstørrelse og debitorrente baseret på din økonomi.',
  calculatorApplyButton: 'Ansøg nu',

  // clear all filters
  clearAllFilters: 'Ryd filtre',

  // leads filter
  filterTitle: 'Filter',
  loanStatus: 'Lånestatus',
  clinic: 'Behandler',
  dateInterval: 'Datointerval',
  resetFilter: 'Nulstil',
  submitFilter: 'Filtrér',
  filterSearchPlaceholder: 'Behandlernavn eller id',
  filterSearchPlaceholderClinic: 'Behandlernavn',
  patientStatus: 'Patientstatus',
  creditStatus: 'Kreditstatus',
  brandStatus: 'Brand',
  deleteFilterText: 'Fjern filteret',

  // company filter
  companyStatus: 'Klinik status',
  companyBrandStatus: 'Klinik brand',
  companyStatusOption: 'Aktiv',
  companySmartLinkOption: 'Smartlink',
  companyCategoryStatus: 'Kategori',
  companyCategoryPlaceholder: 'Alle kategorier',

  // company chain filter

  companyChainStatus: 'Virksomhedens kædestatus',
  companyChainTrialPeriod: 'Prøveperioden slutter på',

  // payment status
  paymentStatus: 'Betalingsstatus',
  latestPayment: 'Senest betalt',
  currentRemaing: 'Restgæld pr. dags dato',
  cardStatus: 'Kortstatus',
  caseStatus: 'Status på sagen',
  personResponsible: 'Ansvarlig',

  // dunning statuses
  dunningCardTitle: 'Dunning',
  dunningNotContacted: 'Ikke kontaktet',
  dunningNoContact: 'Intet svar',
  dunningDialogue: 'Dialog',
  dunningAgreement: 'Indgået aftale',
  dunningDebtCollection: 'Sendes til inkasso',
  dunningNoStatus: 'Ingen status',
  dunningCauseTitle: 'Årsag',
  dunningResponsibleDropdownPlaceholder: 'Vælg venligst...',

  // card status
  noCard: 'Intet kort',
  cardActive: 'Aktivt',
  cardDeclined: 'Spærret',

  never: 'Aldrig',

  // patients in dunning

  loansInDunning: 'Lån i dunning',
  personInDunning: 'Pers. i dunning',
  amountInDunning: 'Forfaldne ydelse',
  totalDuning: 'Samlet hovedstol',
  exportDebtCollection: 'Eksportér inkassosager',
  search: 'Søg',
  patientsDunningsResults: 'Resultater: {0} af {1}',

  clientName: 'Navn',
  totalLoanAmount: 'Lånebeløb',
  lastPaymentPayedDate: 'Sidst betalt',
  remainingBalance: 'Hovedstol',
  monthlyPayment: 'Mnd. ydelse',
  creditCardState: 'Kortstatus',
  dunningStatus: 'Status',
  userInCharge: 'Ansvarlig',
  dunningType: 'Årsag',

  // restructuring
  restTitle: 'Omlægning',
  restDate: 'Dato',
  restReference: 'Reference',
  restInterest: 'Rente',
  restDuration: 'Løbetid',
  restAmount: 'Lånebeløb',
  restBalance: 'Restgæld',
  restTotalRemBalance: 'Samlet restgæld pr. {0}:',
  restFee: 'Omlægningsgebyr',
  restFeeShort: 'Oml.gebyr',
  restLatePaymentFee: 'Rykkergebyrer',
  restLatePaymentFeeShort: 'Rykkere',
  restExtraPayment: 'Indbetalt',
  restFirstPaymentDate: 'Første betaling',
  restFirstPaymentDateShort: '1. betaling',
  restSelectFirstOfMonth: 'Vælg den 1. i en kommende måned',
  restInterestRate: 'Rentesats',
  restCause: 'Årsag',
  restMonths: '{0} mdr.',
  restAPR: 'ÅOP',
  restMonthlyPayment: 'Mnd. ydelse',
  restLoansRestructure: 'Gennemfør omlægning',
  restAllowDuration: 'Lad patienten justere løbetiden',

  // restrucuturing modal
  restModalTitle: 'Bekræft omlægning',
  restModalText:
    'Er du sikker på at du ønsker at omlægge patientens eksisterende lån til et nyt lån med en løbetid på {0}, en rentesats på {1}, et omlægningsgebyr på {2} og en månedlig ydelse på {3}?',
  restModalConfirm: 'Bekræft',
  restModalCancel: 'Fortryd',

  // restructure notification
  restNotificationTitle: 'Omlægningen blev oprettet',
  restNotificationBody:
    'Et godkendelseslink er blevet sendt til {0} via SMS og e-mail.',
  restNotificationTitleError: 'Omlægningen kunne ikke oprettes',

  // dropdown
  dropPatientInDunning: 'Patienten er i dunning',
  dropAmountReturnClinic: 'Beløb retur fra behandler',
  dropPartialRedemption: 'Delvis førtidsindfrielse',
  dropClientRequest: 'Efter patientens ønske',
  dropOther: 'Andet',
  dropPlaceholder: 'Vælg',

  // dunning modal
  dunningExportTitle: 'Eksportér inkassosager',
  dunningExportMessage1:
    'Hvis du fortsætter, vil alle lån tilknyttet patienter med en inkasso-status blive lukket ned.',
  dunningExportMessage2:
    'Det drejer sig om i alt {0} lån fordelt på {1} patienter, og en samlet tilbageværende hovedstol på {2}',
  dunningExportMessage3:
    'Er du sikker på at du ønsker at eksportere disse {0} sager?',
  dunningComfirm: 'Eksportér',
  dunningCancel: 'Fortryd',
  generatingFile: 'Genererer fil ...',

  // duning Cause
  dunningCauseDunning: 'Dunning',
  dunningCauseDeceased: 'Afdød',
  dunningCauseCounseling: 'Gældsrådgivning',
  dunningCauseManual: 'Manuel betaling',
  dunningCardStatusTitle: 'Kortstatus',
  dunningLastPaymentPayedDateTitle: 'Sidste betalingsdato (betalt)',

  // dunning card status
  dunningCardStateActive: 'Aktiv',
  dunningCardStateInactive: 'Inaktiv',
  dunningCardStateFailed: 'Failed',
  dunningCardStateDeleted: 'Slettet',
  dunningCardStateMissing: 'Mangler',

  // suspend
  suspendPatient: 'Suspendér patient',
  unsuspendPatient: 'Fjern suspendering',
  suspendMessage:
    'Kreditten er lukket. Det er ikke muligt at registrere yderligere regningsbeløb.',
  suspendClose: 'Lukket',
  suspend: 'Suspendér',
  reactivate: 'Genaktivér',
  cancelMembership: ' Opsig medlemskab',
  removeSuspension: 'Fjern suspendering',

  // clients messages
  clients: 'Patienter',
  creditExpired: 'Kreditten er udløbet',
  creditClosed: 'Kreditten er lukket',
  clientSuspended: 'Patienten er suspenderet',
  clientDebtCollection: 'Patienten er sendt til inkasso',
  clientDeceased: 'Patienten er afgået ved døden.',
  clientCreditDisabled: 'Medlemmet har ingen aktiv credit',
  clientCreditRejected: 'Kreditten er afvist',
  paymentApproval: 'Regning afventer godkendelse',

  // paid out
  paidOutTitle: 'Beløbet er udbetalt',
  paidOutInvoice: 'Regningsbeløb:',
  paidOutCommision: 'Kommission ({0}%):',
  freePaidOutCommision: 'Gratis prøveperiode:',
  paidOutDate: 'Udbetalt d. {0}:',
  paidOutText1:
    'Beløbet er udbetalt til jeres NemKonto (cvr nr. {0}) med følgeteksten “{1}”.',
  paidOutText2:
    'Beløbet er udbetalt til kontonr. {0} {1} med information om patientens navn og fødselsdato, samt følgeteksten “{2}”.',
  paidOutText3:
    'Vær opmærksom på at der kan gå op til 2 bankdage fra beløbet udbetales, til det er synligt på modtagerkontoen.',
  paidOutDenti: `${brandConfig.appName.toUpperCase()} {0}`,

  // accept
  // step 1
  step1Title: `Godkend betaling med din ${brandConfig.appName} Kredit`,
  loanClinic: 'Behandler:',
  invoiceAmount: 'Regningsbeløb',
  availableCredit: 'Kredit til rådighed:',
  agreeBillQuestion: 'Er du enig i regningsbeløbet?',

  // step 2
  step2Title: 'Tilpas din månedlige betaling',
  billAmount: 'Regningsbeløb:',
  existingLoans: 'Eksisterende lån:',
  acceptSetupFee: 'Etableringsomkostninger:',
  acceptInterestRate: 'Debitorrente:',
  acceptLoanDuration: 'Løbetid:',
  acceptMonthlyPayment: 'Månedlig ydelse:',
  acceptLoanApr: 'ÅOP:',
  acceptTotalCreditCost: 'Samlede kreditomkostninger:',
  totalRepayment: 'Samlet tilbagebetaling:',
  tipSetupFeeTitle: 'Hvad er "etableringsomkostninger"?',
  tipSetupFeeText1: `Hver gang du benytter din ${brandConfig.appName} Kredit, pålægges der etableringsomkostninger svarende til 5% af regningsbeløbet. Sammen med renterne udgør etableringsomkostningerne de såkaldte “samlede kreditomkostninger”. Der hverken løbende administrationsomkostninger eller betalingsgebyrer forbundet med at benytte din kredit.`,
  tipSetupFeeText2: `TIP: Da gebyret er fast en procentsats, vil omkostningerne i sidste ende være de samme for dig, uanset om du benytter din ${brandConfig.appName} Kredit til at finansiere én betaling på 1000 kr. eller to betalinger på 500 kr.`,
  tipExistingLoanTitle: 'Hvad er "eksisterende lån"?',
  tipExistingLoanText1:
    'Beløbet udgør den aktuelle hovedstol på din nuværende betalingsaftale. Beløbet indregnes i den nye aftale, så du altid kun har én aftale at forholde dig til, og dermed kun betaler én samlet månedlig ydelse.',
  tipExistingLoanText2:
    'Vær opmærksom på at hvis renten på din nuværende betalingsaftale er forskellig fra renten på den nye aftale, så vil den nye rente gælde for hele lånebeløbet, også hovedstolen fra den nuværende betalingsaftale.',
  tipDurationText1:
    'Du vælger selv hvor mange måneder du ønsker at betalingsaftalen skal strække sig over. Træk i slideren for at justere løbetiden, og se hvordan det påvirker den månedlige ydelse.',
  tipDurationText2:
    'Som udgangspunkt kan du frit vælge en løbetid på mellem {0} og {1} måneder. Hvis du oplever at intervallet er indskrænket, skyldes det at lovgivningen på området forhindrer os i at tilbyde en løbetid, hvor de årlige omkostinger i procent overstiger 25%.',

  // step 2 restructured
  step2RestTitle: 'Omlægning af betalingsaftale',
  currentPrincipal: 'Skyldig hovedstol:',
  latePaymentFee: 'Heraf rykkergebyrer:',
  restructureFees: 'Omlægningsgebyr:',
  newDuration: 'Ny løbetid:',
  firstInstallmentDueDate: 'Betaling af 1. ydelse:',
  futurePayment: 'Fremtidig månedlig ydelse:',
  reepayInvoiceSettleMessage: 'Afventer bekræftelse af betaling',
  reepayInvoiceSettledError:
    'Desværre fik vi ikke success med afregningen, kontakt venligst vores kundeservice for at få løst problemet.',

  // step 3
  step3Title: 'Accepter betalingsaftale og aftalebestemmelser',
  titleAgreement: 'Betalingsaftale',
  subTitleAgreement: 'Aftalevilkår',
  numberAgreement: 'Aftalenr.',
  establishmentDate: 'Etableringsdato:',
  firstInstallmentDate: 'Betaling af første ydelse',
  lastInstallmentDate: 'Betaling af sidste ydelse',
  loanAmountAgreement: 'Lånebeløb:',

  // step 4
  step4Title: 'Er du ny patient hos',
  firstVisitText:
    'For at kunne yde den bedst mulige service overfor behandleren, vil vi gerne vide om det er første gang du benytter denne behandler.',
  yesVisit: 'Ja, det er første gang jeg benytter denne behandler',
  noVisit: 'Nej, jeg har tidligere benyttet denne behandler',

  // step 5
  step5Title: 'Vælg betalingsmetode',
  paymentDetails:
    'Fremadrettet vil vi hver måned trække {0} på dit betalingskort.',
  creditCardDetails: 'Nuværende betalingskort',
  creditCardExpires: 'Udløber',
  useCurrentCard: 'Benyt fortsat dette betalingskort',
  useDifferentCard: 'Benyt et andet betalingskort',
  loadingMessage: 'Vent venligst...',

  // step 6
  step6Title: 'Tilføj dine betalingsoplysninger',
  registerCardText:
    'For at vi kan trække din månedlige ydelse, bedes du registrere et betalingskort.',
  registerCardLater:
    'Jeg ønsker at tilføje mine kortoplysninger på et senere tidspunkt.',

  // step accept
  stepAcceptTitle: 'Sådan!',
  acceptWithCardText:
    'Betalingsaftalen er oprettet, og vi vil sørge for at klinikken modtager sin betaling indenfor 24 timer.',
  acceptWithCardText1: `Den månedlige ydelse på din ${brandConfig.appName} Kredit vil fremadrettet være {0}, som vi trækker på dit betalingskort hver måned over {1} måneder.`,
  sendEmailWithCard:
    'Vi har sendt dig en e-mail på {0} med alle detaljer vedrørende betalingsaftalen.',
  sendEmailWithoutCard:
    'Vi har sendt dig en e-mail ({0}) med alle detaljer vedrørende betalingsaftalen, samt et link til at tilføje dit betalingskort.',
  acceptFirstPayment: 'Første trækning: {0}.',
  acceptObservation: `Det er vigtigt at du tilføjer dit betalingskort inden første betalingsdato, da du ellers risikerer at blive pålagt rykkergebyrer. Du kan desuden ikke kan benytte din ${brandConfig.appName} Kredit til yderligere betalinger, før du har tilføjet dine kortoplysninger.`,

  // step accept restructured
  acceptRestText: 'Din betalingsaftale er nu omlagt.',
  acceptRestText1: `Den månedlige ydelse på din ${brandConfig.appName} Kredit vil fremadrettet være {0}, som vi trækker på dit betalingskort hver måned over {1} måneder.`,
  acceptRestDueDate: 'Første ydelse trækkes: {0}.',
  acceptRestText2:
    'Vi har sendt dig en e-mail på {0} med alle detaljer vedrørende betalingsaftalen.',

  // step decline
  stepDeclineTitle: 'Afvist betaling',
  declineText: `Du har afvist at betale regningsbeløbet med din ${brandConfig.appName} Kredit.`,
  declineText1:
    'Hvis din afvisning skyldes, at du mener at regningsbeløbet på {0} er forkert, bedes du venligst gøre behandleren opmærksom på dette.',
  declineText2: `Hvis du har afvist aftalen, fordi du har spørgsmål til betalingsvilkårerne eller din ${brandConfig.appName} Kredit generelt, er du meget velkommen til at kontakte kundeservice.`,
  declineCallText: 'Ring til kundeservice på {0} tlf. {1}',
  declineEmailText: 'Send en e-mail til kundeservice',
  showMoreTehnicalInformation: 'Se fejlkode til brug for teknisk support',
  httpText: 'HTTP:',
  httpMessage: 'Message:',
  httpCause: 'Cause:',

  // step decline restructured
  declineRestText:
    'Du har afvist omlægningen af din betalingsaftale, og din nuværende betalingsaftale er derfor fortsat gældende.',
  declineRestText1:
    'Hvis din afvisning skyldes, at du mener, at der er fejl i de angivne beløb eller betalingsbetingelser, bedes du venligst kontakte kundeservice hurtigst muligt, og gøre opmærksom på problemet.',

  // actions
  declineLoan: 'Afvis',
  continueStep: 'Fortsæt',
  acceptStep: 'Acceptér',

  // exceptions
  exceptionDeletedTitle: 'Regningen eksisterer ikke længere',
  exceptionDeletedText1:
    'Det ser ud til, at den regning du forsøger at godkende, er blevet slettet i vores system.',
  exceptionDeletedText2:
    'Dette kan ske hvis klinikken fx opdager at regningsbeløbet er forkert.',
  exceptionDeletedText3:
    'I sådanne tilfælde vil klinikken ofte oprette en ny regning på det korrekte beløb, og du vil i så fald have modtaget endnu en besked, med et link til at godkende den opdaterede regning.',
  exceptionDeletedText4:
    'Hvis du har nogen spørgsmål, er du altid meget velkommen til at kontakte os på {0} eller tlf. {1}',

  exceptionNotFoundTitle: '404 - ikke fundet',
  exceptionNotFoundText1:
    'Siden du ønsker at besøge, ser ikke ud til at eksistere.',
  exceptionNotFoundText2:
    'Det er sikkert os der har begået en fejl, så tøv ikke med at kontakte os på {0} eller tlf. {1} hvis problemet fortsætter.',

  exceptionComfirmedTitle: 'Betalingsaftalen er allerede aktiveret',
  exceptionComfirmedText1:
    'Da vi sender godkendelseslinket både som e-mail og på sms, har du sandsynligvis allerede været inde at godkende regningen og aktivere betalingsaftalen.',
  exceptionComfirmedText2:
    'I så fald bør du allerede have modtaget en e-mail med detaljeret information om aftalen.',
  exceptionComfirmedText3:
    'Hvis dette ikke er tilfældet, eller du har spørgsmål til din aftale, er du altid meget velkommen til at kontakte os på {0} eller tlf. {1}',

  exceptionExpiredTitle: 'Linket er udløbet',
  exceptionExpiredText1:
    'Af sikkerhedsmæssige årsager er godkendelseslinks kun gyldige i 48 timer.',
  exceptionExpiredText2: `For at få tilsendt et nyt godkendelseslink, bedes du kontakte din behandler eller ${brandConfig.appName} på {0} eller tlf. {1}.`,

  resetLinkExpiredTitle: 'Dette link virker ikke længere',
  resetLinkExpiredSubtitle: 'Dette kan skyldes:',
  resetLinkExpiredReason1: 'Link er udløbet',
  resetLinkExpiredReason2: 'Link er blevet brugt',
  resetLinkExpiredReason3: 'Link har aldrig virket',
  resetLinkParagraph:
    'Kontakt venligst kundeservice på {0} eller tlf. {1} hvis problemet fortsætter.',

  // error
  errorServerTitle: 'Noget gik galt...',
  errorServerText1:
    'Vi beklager meget, men det ser ud til at der er opstået en teknisk fejl. Opdatér eventuelt siden og forsøg igen.',
  errorServerText2:
    'Hvis problemet fortsætter, bedes du kontakte kundeservice, som vil være behjælpelige med at finde en løsning.',

  // server rebooting error
  serverRebootingHeadline: 'Det ser ud til, at vi lige har opdateret',
  serverRebootingText:
    'Vi beklager meget, men det ser ud til, at der er opstået en teknisk fejl, mens vi opdaterede. Forsøg venligst igen.',
  serverRebootingButton: 'Luk',
  serverRebootingOKButton: 'OK',
  // server down error
  errorServerDownTitle:
    'Der lader til at være problemer med forbindelsen til serveren',
  errorServerDownText1:
    'Vi beklager meget, men det ser ud til at der er et problem med din forbindelse. Opdatér eventuelt siden og forsøg igen.',
  errorServerDownText2:
    'Hvis problemet fortsætter, bedes du kontakte kundeservice, som vil være behjælpelige med at finde en løsning.',

  // add patient
  sidebarMenuAddPatient: 'Tilføj patient',
  buttonTextAddPatient: 'Tilføj patient',
  buttonTextClose: 'Luk',
  buttonTextTryAgain: 'Forsøg igen',
  buttonTextSendLink: 'Send link',
  buttonTextShowClient: 'Se patientens kredit',

  // step 1
  apStep1Title: `Har patienten allerede en ${brandConfig.appName} Kredit?`,
  apStep1HelpText: `Er du blevet oplyst om at patienten allerede har en aktiv ${brandConfig.appName} Kredit, som patienten ønsker at benytte til at finansiere sin behandling med?`,
  apStep1YesTitle: `Ja, patienten har allerede en ${brandConfig.appName} Kredit`,
  apStep1YesText: `Når du tilføjer en patient med en eksisterende ${brandConfig.appName} Kredit, får du øjeblikkeligt adgang til at registrere regningsbeløb på patientens kredit.`,
  apStep1NoTitle: `Nej, patienten har endnu ingen ${brandConfig.appName} Kredit`,
  apStep1NoText: `Hjælp patienten med at få en ${brandConfig.appName} Kredit ved at sende patienten et ansøgningslink.`,
  apStep2PlaceholderTextForClinic: 'klinikken',

  // step 2
  apStep2Title: `Tilføj patient med eksisterende ${brandConfig.appName} Kredit`,
  apClinicLabel: 'Filial som patienten skal tilføjes til',
  apCprNumber: 'Patientens cpr-nummer:',
  apLettersName: 'De to første bogstaver i patientens fornavn:',
  apStep2TextInfo: `Når du tilføjer en ny patient, vil patienten modtage en besked om at {0} nu har fået adgang til hans ${brandConfig.appName} Kredit.`,

  // step 3
  apPatientAddedTitle: 'Patienten er tilføjet',
  apPatientAddedText1: `Du har nu adgang til {0}s ${brandConfig.appName} Kredit.`,
  apPatientAddedText2:
    'Vi har desuden sendt {0} en besked om at {1} nu har mulighed for at tilføje regningsbeløb på kreditten.',

  apCreditExpiredTitle: 'Kreditten er udløbet',
  apCreditExpiredText1: `Du har nu adgang til {0}s ${brandConfig.appName} Kredit.`,
  apCreditExpiredText2:
    'Før du kan registrere et regningsbeløb, skal {0} dog først have fornyet sin kredit - Det tager 2 minutter og kan klares via mobilen. Vi har sendt {1} en sms med et link til at ansøge om at få kreditten fornyet.',

  apNotFoundTitle: 'Patienten blev ikke fundet',
  apNotFoundText1:
    'Vi kunne ikke finde en patient med cpr-nummeret {0} og et fornavn der starter med {1}.',
  apNotFoundText2: 'Tjek venligst at du har indtastet de korrekte oplysninger.',
  apNotFoundText3: `Hvis patienten ikke allerede har en ${brandConfig.appName} Kredit, kan du i stedet`,
  apNotFoundLink: 'sende patienten et ansøgningslink.',

  apCreditClosedTitle: 'Kreditten er lukket',
  apCreditClosedText1: `{0}s ${brandConfig.appName} Kredit er blevet lukket, og kan ikke længere benyttes.`,
  apCreditClosedText2:
    'Patienten er allerede blevet underrettet om dette, og vi opfordrer til at patienten i stedet betaler for sin behandling kontant.',

  apSendLinkTitle: 'Send ansøgningslink',
  apSendLinkText1: `Har du en patient som ønsker at oprette en ${brandConfig.appName} Kredit?`,
  apSendLinkText2:
    'Indtast patientens e-mailadresse eller mobilnummer for at sende patienten et link til at ansøge om en kredit. Ansøgningsprocessen tager under 2 minutter og kan gennemføres alene ved brug af MitID.',
  apSendLinkNumber: 'Patientens mobilnr.',
  apSendLinkEmail: 'Patientens e-mailadresse',
  apSendLinkOrLabel: 'eller',
  apConfirmAgreement: `Jeg bekræfter at patienten har givet tilladelse til at modtage en sms/e-mail fra ${brandConfig.appName}, med et link til at ansøge om en ${brandConfig.appName} kredit.`,
  apPhoneLabel: 'Mobiltelefon',

  apLinkSuccessTitle: 'Ansøgningslinket blev sendt',
  apLinkSuccessText1: 'Patienten bør modtage linket i løbet af få sekunder.',
  apLinkSuccessText2: `Hvis patienten ansøger via det tilsendte link, og bliver godkendt til en ${brandConfig.appName} Kredit, vil I kunne tilgå kreditten umiddelbart herefter.`,
  apLinkSuccessTextEmail: 'Vi sender jer også en underretning på e-mail.',
  apLinkSuccessTextSms: 'Vi sender jer også en underretning på sms.',

  apClientAssignedTitle: 'Du har allerede adgang',
  apClientAssignedText1: `Du har allerede adgang til {0}s ${brandConfig.appName} Kredit.`,
  apClientAssignedText2:
    '{0} Brug søgefeltet i toppen af listen med patienter til at søge blandt alle de patienter du har adgang til. Du kan søge på både navn og cpr-nummer.',
  apClientAssignedTip: 'TIP:',

  // live agent
  multipleMatches: 'Flere matches',
  multipleMatchesNote:
    'Der findes flere patienter i DAM som matcher afsenderen på denne ticket.',
  openDAM: 'Åbn DAM',
  noMatch: 'Intet match',
  noMatchNote:
    'Det lykkedes ikke at finde en patient i DAM der matcher afsenderen på denne ticket.',
  openPatientDAM: 'DAM',
  openPatientReepay: 'Reepay',
  openPatientCustomerIo: 'Customer.io',
  chooseClinic: ' Email-flow med valg af behandler',
  memberAvailableCredit: 'Tilgængelig kredit',
  membershipCreated: 'Medlemskab oprettet',
  creditLastRenewed: 'Kredit senest fornyet',
  rmnPrincipal: 'Aktuel hovedstol',
  activeLoans: 'Aktive lån',
  loans: 'lån',
  creditCard: 'Betalingskort',
  clinicsWithAccess: 'Klinikker med adgang til kreditten:',
  applicationRejected: 'Ansøgning afvist',
  notApplied: 'Ikke ansøgt',
  applicationNotFinished: 'Patienten har endnu ikke færdiggjort sin ansøgning.',
  liveAgentUpdateCreditCard: 'Opdater',
  liveAgentUpdateCreditCopied: 'Kopieret',
  liveAgentUpdateCreditLabel: 'Tilføj kort',

  // live agent credit status
  liveAgentCreditStatus_ACTIVE: 'Aktiv',
  liveAgentCreditStatus_REJECTED: 'Ansøgning afvist',
  liveAgentCreditStatus_SUSPENDED: 'Suspenderet',
  liveAgentCreditStatus_LATE_PAYMENT: 'Sendt til inkasso',
  liveAgentCreditStatus_DISABLED: 'Ikke ansøgt',
  liveAgentCreditStatus_EXPIRED: 'Udløbet',

  // live agent tabs
  liveAgentTabBookingHeadline: 'Bookingdetaljer',
  liveAgentTabPatientHeadline: 'Patientdetaljer',
  liveAgentTabBookingParagraphV1: 'Status på bookingforespørgsel',
  liveAgentTabBookingParagraphV2: 'Status på behandleroprettelse',
  liveAgentTabBookingPlaceholder: 'Vælg status',
  liveAgentTabBookingCreated: 'Forespørgsel oprettet',
  liveAgentTabBookingExpiration: 'Udløber om',
  liveAgentTabBookingClinic: 'Filial',
  liveAgentTabBookingClinicStatus: 'Filialstatus',
  liveAgentTabBookingSwitchText:
    'Filialen har givet accept til at modtage bookingforespørgsler på email',
  liveAgentTabBookingReceiverEmail: 'Modtager',
  liveAgentTabBookingEmailCreatedDate: 'Email sendt',
  liveAgentTabBookingButtonSend: 'Send bookingforespørgsel',
  liveAgentTabBookingButtonSee: 'Se filial i DAM',
  liveAgentTabBookingButtonCompanySlug: 'Se profil i behandlerindekset',
  liveAgentTabBookingButtonCustomerIO: 'Se e-mail i Customer.io',

  // chain
  registrationNumber: 'kontonr',
  trialEnd: '0% i bidrag til og med',
  marketingFee: 'Markedsføringsbidrag',
  marketingFeeCustomMsg: 'standard værdi',
  marketingFeeEditCustomMsg:
    'Standardsatsen (pt. {0}) benyttes hvis intet gebyr angives',
  marketingFeeError: 'Punktum er ikke tilladt, brug venligst komma',
  new: 'Ny',
  newChainTitle: 'Opret behandler',
  cvrNumber: 'CVR nr.',
  chainStatus: 'Behandlerstatus',
  chainStatusClosed: 'Ophørt',
  chainDeleteConfirmMesage:
    'Er du sikker på, at du ønsker at slette behandleren, {0}, og alle tilknyttede filialer?',
  blacklistCVR: 'Blacklist CVR-nummer, {0}',
  chainEditCVRHeadlineBlackList: 'Blacklist CVR',
  chainEditCVRBodyBlackList:
    'Undgå at CVR nummeret automatisk bliver gen-importeret ved at klikke "Blacklist".',
  chainEditCVRBodyBlackListSecond:
    'Hvis CVR nummeret skal bruges på en anden eksisterende behandler, kan du blot trykke bekræft uden at markere checkboxen.',
  chainFee: 'Bidrag',
  chainRevenue: 'Omsætning',
  companyCreateDate: 'Oprettet',
  seeDetails: 'Se detaljer',
  companyChainsMenuTitle: 'Behandlere',
  seeCustomerIO: 'Se i Customer.io',
  navigateToApplications: 'Ansøgninger',
  edit: 'Redigér',
  delete: 'Slet',
  cvrError: 'CVR skal være 8 cifre',
  cvrExists: 'En behandler eksisterer allerede med dette CVR nummer {0}',
  cvrExistsShort: 'CVR eksisterer allerede',
  companyChainNameError: 'Indtast venligst et navn',
  kotnirError: 'Kontonr. er for kort',
  searchForchain: 'Søg efter behandler...',
  errorCvcNew:
    'CVR-nummeret ser ikke ud til at være gyldigt. Det skal være på 8 cifre.',
  errorShortCompanyName: 'Firmanavnet er for kort',
  errorOnlyNumbersAllowed: 'Kun tal er tilladt',

  chainDeleteError: 'Det lykkedes ikke at slette behandleren {0}.',
  companyDeleteError: 'Klinik {0} blev ikke slettet på grund af en fejl.',
  nameAndPhone: 'Navn og addresse',
  clinicNote: 'Note til behandler',
  createdOn: 'Oprettet d.',
  memberSince: 'Medlem siden d.',
  membershipCanceled: 'Medlemskab opsagt',
  deleteLeadTitle: 'Slet (GDPR)',
  deleteLeadContent:
    'Bekræft venligst at du ønsker at slette {0} fra systemet. Al persondata vedrørende patienten slettes permanent, og kan ikke gendannes. ',
  cancelLeadConfirmTitle: 'Opsig medlemskab',
  canceldLeadConfirmContent:
    'Bekræft venligst at du ønsker at opsige {0}s medlemskab.  ',
  onboardingLidProp: 'Oprettelse afbrudt',
  chainUsers: 'Brugere',
  chainAddUser: 'Tilføj bruger',
  chainCreateUser: 'Opret bruger',
  chainEditUser: 'Redigér bruger',
  chainUserType: ' Brugertype',
  chainAssociatedLocation: 'Tilknyttet filial',
  chainSelectLocation: 'Vælg filial',
  chainAdministrator: 'Administrator',
  chainManager: 'Manager',
  chainEmployee: 'Medarbejder',
  chainAdmin: 'Administrator',
  chainEdit: 'Redigér',
  chainDelete: 'Slet',
  chainResendInvitation: 'Gensend invitation',
  chainNever: 'Aldrig',
  chainPendingInvitation: 'Afventende invitation',
  chainResetPAssword: 'Nulstil adgangskode',
  chainResendInvitationNote:
    ' Ønsker du at gensende invitationen med aktiveringslinket til brugeren ',
  chainDeleteUserNote: ' Ønsker du at slette brugeren ',
  chainInvoicesRegisteredNote:
    'Regninger som brugeren har indtastet slettes ikke.',
  chainSendUserNote1: 'Ønsker du at sende brugeren ',
  chainSendUserNote2: ' et link til at nulstille sin adgangskode?',
  chainFullAccess:
    'Fuld adgang til alle patienter og indstillinger på behandlerniveau',
  chainAccessSettings:
    'Adgang til indstillinger og patienter for en specifik filial',
  chainAccessAdmin:
    'Fuld adgang til alle patienter og indstillinger til hele DAM',
  chainUserRole: 'Rolle',
  chainUserLatestLogin: 'Seneste login',
  chainUserConfirmResetTitle: 'Bekræft nulstilling',
  userNoCompanySelected:
    'For at oprette en bruger, skal der være tilknyttet minimum én filial til behandleren.',
  userSaveBtn: 'Gem',
  userCancelBtn: 'Annuller',
  selectUserTypePlaceholder: 'Vælg brugertype',
  selectUserLocationPlaceholder: 'Vælg filial',
  create: 'Opret',
  chainSendInformation:
    'Der sendes en e-mail til den angivne e-mailadresse med et link til at aktivere brugeren og oprette en adgangskode.',
  userRegistrationActiveUser: 'Aktivér bruger',
  userRegistrationCreateSecurePassword:
    'Opret en sikker adgangskode til din bruger for at komme i gang.',
  userRegistrationPassword: 'Adgangskode',
  userRegistrationPasswordRepeat: 'Gentag adgangskode',
  userRegistrationPasswordRequermets: 'Krav til adgangskoden:',
  userRegistrationPasswordRequermets1: 'Minimum 8 tegn',
  userRegistrationPasswordRequermets2: 'Minimum ét stort bogstav',
  userRegistrationPasswordRequermets3: 'Minimum ét lille bogstav',
  userRegistrationPasswordRequermets4: 'Minimum ét tal',
  userRegistrationCancel: 'Afbryd',
  userRegistrationContinue: 'Fortsæt',
  userRegistrationUserInformation: 'Brugeroplysninger',
  userRegistrationEditContactInformation:
    'Tilføj/ret dine øvrige kontaktoplysninger',
  userRegistrationFirstName: 'Fornavn',
  userRegistrationLastName: 'Efternavn',
  userRegistrationPhone: 'Telefon',
  userRegistrationTermsAndConditions: 'Acceptér brugerbetingelser',
  userRegistrationAborted: 'Afbrudt',
  userRegistrationTermsNedsAccepted:
    'Din bruger kan ikke aktiveres uden at du godkender brugerbetingelserne.',
  userRegistrationForMoreQuestions: `Hvis du har spørgsmål til brugerbetingelserne eller til samarbejdet med ${brandConfig.appName} generelt, er du altid meget velkommen til at kontakte os på tlf. ${brandConfig.contactPhoneHtmlFormated} eller på `,
  userRegistrationBack: 'Tilbage',
  userRegistrationThatsIt: 'Sådan!',
  userRegistrationReadyPaiments: `Du er nu klar til at tage imod betaling fra patienter med en ${brandConfig.appName} Kredit.`,
  userRegistrationSetpGuide:
    'Et regningsbeløb indtastes med få klik i systemet, og patienten accepterer direkte på sin mobil.',
  userRegistrationSetpGuideLink:
    'Se guiden til hvordan du registrerer en regning.',
  userRegistrationReadyToHelp:
    'Hvis du får brug for hjælp, sidder kundeservice altid klar til at hjælpe dig, både på telefon og e-mail.',
  userRegistrationLogIn: 'Log ind',
  userRegistrationCreateUaser: 'Opret Bruger',
  userAlredyActivated1: 'Din bruger er allerede aktiveret',
  userAlredyActivated2:
    'Det ser ud til at du allerede har aktiveret din bruger. ',
  userAlredyActivatedButton: 'Gå til log ind',
  userNotFound1: 'Brugeren blev ikke fundet',
  userNotFound2:
    'Det ser ikke ud til at brugeren eksisterer. Kontakt venligst den person som har oprettet brugeren til dig, hvis du mener at der er sket fejl.',
  linkExpiredButton: 'Send nyt link',
  userLinkExpired1: 'Linket er udløbet',
  userLinkExpired2:
    'Af sikkerhedsmæssige årsager er aktiveringslinket kun gyldigt i 7 dage.',
  userLinkExpired3: 'Klik på knappen herunder, for at få tilsendt et nyt link.',
  userLinkExpiredButton: 'Send nyt aktiveringslink',
  userRegistrationStep2Note: 'Tilføj/ret dine øvrige kontaktoplysninger',
  userregistrationMailError:
    'Der eksisterer allerede en bruger med denne e-mail',
  userRegistrationExistingUser: 'Brugernavnet eksisterer allerede',
  userRegistrationPleaseEnterMail: 'Indtast venligst en gyldig e-mailadresse',
  userRegistrationPleaseEnterUserType: 'Vælg venligst en brugertype',
  userRegistrationPleaseEnterUserCompany: 'Vælg venligst en brugertype',
  cvrLabel: 'CVR',
  locationFlow_step1_title: 'Opret filial',
  locationFlow_step1_name: 'Filialnavn',
  locationFlow_step1_adress: 'Adresse',
  locationFlow_step1_zipCode: 'Postnr.',
  locationFlow_step1_city: 'By',
  locationFlow_step1_cvr: 'CVR-nummer',
  locationFlow_step1_cvrSwitch: 'Filialen har ikke et selvstændigt CVR-nummer.',
  locationFlow_step1_requerdField: 'Udfyld venligst alle felter',
  locationFlow_step1_continue: 'Fortsæt',
  locationFlow_step2_title: 'Kontaktinformationer',
  locationFlow_step2_description: `Angiv venligst filialens kontaktinformationer og vælg én eller flere kategorier der matcher med de behandlinger filialen tilbyder. Informationerne vil blive vist i ${brandConfig.appName}’s behandlerindeks. `,
  locationFlow_step2_phone: 'Telefon',
  locationFlow_step2_email: 'E-mail',
  locationFlow_step2_website: 'Website',
  locationFlow_step2_categories: 'Kategorier',
  locationFlow_step2_placeholder_categories:
    'Vælg venligst én eller flere kategorier...',
  locationFlow_step2_hide: 'Filialen skal ikke vises i {0}’s behandlerindeks',
  locationFlow_step2_back: 'Tilbage',
  locationFlow_step2_error: 'Vælg venligst en kategori',
  locationFlow_step2_error2: 'Udfyld venligst de påkrævede felter',
  locationFlow_step2_continue: 'Fortsæt',
  locationFlow_step3_title: 'Bankkonto',
  locationFlow_step3_description1:
    'Når en patient godkender en betaling med sin {0} Kredit, udbetales beløbet til filialen indenfor 24 timer.',
  locationFlow_step3_description2:
    'Vælg venligst hvortil regningsbeløb ønskes udbetalt:',
  locationFlow_step3_cvrTarget: 'for CVR-nr.  {0}',
  locationFlow_step3_anotherBank: 'Anden bankkonto i dansk pengeinstitut',
  locationFlow_step3_regno: 'Regnr.',
  locationFlow_step3_regno_error: 'Skal være 4 cifre',
  locationFlow_step3_acountNo: 'Kontonr.',
  locationFlow_step3_acountNo_error: 'Skal være 10 cifre',
  locationFlow_step3_selectionMeaning:
    'Dit valg medfører at alle godkendte regningsbeløb som registreres på denne filial vil blive udbetalt til NemKontoen tilhørende cvr nr. {0}.',
  locationFlow_step3_noResp:
    'Ved udbetaling til anden konto end NemKonto, er det dit ansvar at indtaste det korrekte kontonummer. {0} ApS kan ikke holdes ansvarlig for beløb der udbetales til en forkert bankkonto grundet fejlagtigt indtastet kontonummer.',
  locationFlow_step3_selectOption: 'Vælg venligst en udbetalingsmetode',
  locationFlow_step3_enterNumber: 'Udfyld venligst registrerings- og kontonr.',
  locationFlow_step4_visible:
    'Filialen vil fremgå af behandlerindekset indenfor 60 minutter.',
  locationFlow_step4_Manager:
    'Alle eksisterende brugere med Manager-rettigheder kan nu registrere regninger på filialen.',
  locationFlow_step4_createUser: 'Opret en ny bruger til denne filial.',
  locationFlow_step4_close: 'Luk',
  locationFlow_step4_cvr: 'CVR',
  locationFlow_step4_adress: 'Adresse',
  locationFlow_step4_description: 'Filialen “{0}” er nu oprettet. ',
  locationFlow_step4_title: 'Filial oprettet',
  locationFlow_step4_accountNo: 'Reg-. og kontonr.',
  locationFlow_step4_phone: 'Telefon',
  locationFlow_step4_email: 'E-mail',
  locationFlow_step4_web: 'Website',
  locationFlow_step4_notSet: '- Ikke angivet -',
  flial_tab_name_filialer: 'Filialer',
  addLoaction: 'Opret filial',

  // Booking request

  labelTLF: 'tlf.',
  labelComment: 'Kommentar:',
  time_ranges: 'ØNSKER EN TID I ET AF FØLGENDE TIDSINTERVALLER:',
  noTimeSlot: 'Så hurtigt så muligt',
  noTimeSlotHeadLine: 'Ønsker til tidspunkt:',
  date_abbreviation: ' d. ',
  time_abbreviation: ' kl. ',
  timer_hour: 'Timer',
  timer_min: 'Min',
  timer_seconds: 'Sek',
  header_title: 'Bookingforespørgsel',
  therapist_profile: 'I har modtaget denne bookingforespørgsel via jeres ',
  therapist_profile1: 'behandlerprofil ',
  therapist_profile2: 'i behandlerindekset på sundhedplus.dk.',
  contact_patient_title: 'Kontakt patienten',
  time_intervals:
    'I opfordres til at tage kontakt til patienten og enten bekræfte en tid i et af de tidsintervaller som patienten har ønsket, eller foreslå patienten et alternativt tidspunkt.',
  resolved_query:
    'Når I har været i dialog med patienten, kan I med fordel markere forespørgslen som løst.',
  btn_solved: 'Markér som løst',
  btn_markedSolved: 'Markeret som løst',
  automatic_delete_msg:
    'Af sikkerhedsmæssige årsager og for at sikre patienten et hurtigt svar, slettes denne forespørgsel automatisk om:',
  errorRequestBookingHeader: 'Ingen bookingforespørgsel fundet',
  errorRequestBookingP1:
    'Vi kan ikke finde den ønskede bookingforespørgsel i vores system.',
  errorRequestBookingP2:
    'Tøv ikke med at kontakte os på {0} eller tlf. {1} hvis problemet fortsætter.',
  booking_help_msg: 'Har I spørgsmål?',
  errorLinkExpiredHeader: 'Bookingforespørgslen er udløbet',
  errorLinkExpiredP1:
    ' Af sikkerhedsmæssige årsager er denne bookingforespørgsel ikke længere tilgængelig.',
  errorLinkExpiredP2:
    'Kontakt venligst kundeservice på tlf. {1} eller send en email til {0} hvis du har spørgsmål.',

  // Migrate credit
  migrateCredit_title_step1: `Overfør din Denti Kredit til ${brandConfig.appName}`,
  migrateCredit_first_paragraph_step1:
    'Opret et gratis Sundhed+ medlemskab, og få samtidig konverteret din Denti Kredit på {0} til en Sundhed+ Kredit på et tilsvarende beløb.',
  migrateCredit_second_headline_step1:
    'Brug kreditten hos over 10.000 behandlere',
  migrateCredit_second_paragraph_step1:
    'I modsætning til din nuværende Denti Kredit, kan du benytte din Sundhed+ Kredit til alle typer af sundhedsbehandlinger. Og du kan selvfølgelig også fortsat benytte kreditten til tandbehandlinger.',
  migrateCredit_third_headline_step1: 'Samme rente og vilkår',
  migrateCredit_third_paragraph_step1:
    'Både din kredit og dine eksisterende betalingsaftaler overflyttes fra Denti til Sundhed+. Rente og vilkår forbliver de samme.',
  migrateCredit_button_step1: `Opret ${brandConfig.appName} medlemskab`,

  migrateCredit_title_step2: 'Medlemsbetingelser',
  migrateCredit_first_paragraph_step2:
    'Som medlem af Sundhed+ får du adgang til en række tjenester og fordele. Medlemskabet er gratis, og kan til enhver tid opsiges.',
  migrateCredit_second_paragraph_step2:
    'Som en del af medlemskabet, vil du ca. 2 gange om måneden modtage vores medlemsnyhedsbrev, med inspirerende artikler om sundhed, samt eksklusive tilbud på sundhedsydelser fra sundhedsbehandlere i dit nærområde.',
  migrateCredit_read_more_step2: 'Læs de fulde medlemsbetingelser',
  migrateCredit_checkbox_step2:
    'Jeg erklærer, at jeg har læst og accepterer medlemsbetingelserne',

  migrateCredit_title_step3: 'Tilføj dine kortoplysninger',
  migrateCredit_first_paragraph_step3:
    'Af sikkerhedsmæssige grunde kan vi ikke overføre dine kortoplysninger fra Denti til Sundhed+.',
  migrateCredit_second_paragraph_step3:
    'Indtast venligst dine kortoplysninger på ny.',

  migrateCredit_button_next: 'Fortsæt',
  migrateCredit_button_back: 'Tilbage',

  migrateCredit_title_step4: 'Velkommen!',
  migrateCredit_subtitle_step4: 'Dit medlemskab af Sundhed+ er nu oprettet',
  migrateCredit_first_paragraph_text1_step4:
    'Vi er glade for at kunne byde dig velkommen som nyt medlem af Sundhed+.{0} Din Denti Kredit på {1} er nu konverteret til en Sundhed+ Kredit med en tilsvarende kreditramme, og vi har ligeledes overført din eksisterende betalingsaftale, så din månedlige ydelse fremover vil blive trukket af Sundhed+.',
  migrateCredit_first_paragraph_text2_step4:
    'Vi er glade for at kunne byde dig velkommen som nyt medlem af Sundhed+.{0} Din Denti Kredit på {1} er nu konverteret til en Sundhed+ Kredit med en tilsvarende kreditramme.',
  migrateCredit_first_paragraph_text_for_expired_step4:
    'Vi er glade for at kunne byde dig velkommen som nyt medlem af Sundhed+.',
  migrateCredit_second_paragraph_text_for_expired_step4:
    'Din eksisterende Denti-betalingsaftale er nu blevet migreret til Sundhed+, og fremadrettet vil du derfor opleve at de månedlige betalingskvitteringer vil komme fra Sundhed+.{0} Alt andet forbliver dog uændret, herunder din rentesats, den månedlige ydelse og de generelle aftalevilkår.',

  migrateCredit_text3_step4_headline: '	Du har allerede en Sundhed+ Kredit',
  migrateCredit_first_paragraph_text3_step4:
    'Din Denti Kredit er allerede konverteret til en Sundhed+ Kredit med en kreditramme på {0} Din månedlige ydelse bliver ligeledes trukket af Sundhed+ i henhold til din betalingsaftale.',
  migrateCredit_second_paragraph_step4:
    'Dit aktuelle rådighedsbeløb på {0}, kan nu benyttes til at finansiere sundhedsbehandlinger hos mere end 10.000 sundhedsbehandlere over hele landet.',
  migrateCredit_first_paragraph_text3_expired:
    'Din Denti Kredit er allerede konverteret til en Sundhed+ Kredit. Din månedlige ydelse bliver ligeledes trukket af Sundhed+ i henhold til din betalingsaftale.',
  migrateCredit_third_paragraph_step4: 'Første trækning: {0}.',
  migrateCredit_subsection_title_step4: 'Kom godt i gang...',
  migrateCredit_subsection_paragraph_step4:
    'Vi har sendt dig en e-mail på {0}, med information om dit medlemskab og de fordele du kan se frem til som medlem af Sundhed+.',
  migrateCredit_SundhedCredit_DentiLoan_title:
    'Overfør betalingsaftaler til Sundhed+',
  migrateCredit_SundhedCredit_DentiLoan_p1:
    'Du afdrager i dag på én eller flere betalingsaftaler gennem Denti. Da vi har valgt at sammenlægge Denti med Sundhed+, er det i den forbindelse nødvendigt at overføre dine betalingsaftaler til Sundhed+.',
  migrateCredit_SundhedCredit_DentiLoan_p2:
    'Da du allerede er medlem af Sundhed+, er det dog hurtigt klaret. Alt hvad vi har behov for, er din accept af at vi overfører betalingsaftalerne, samt at du genindtaster dine kortoplysninger, da vi af sikkerhedsmæssige grunde ikke kan flytte dem med over.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_title:
    'Overfør betalingsaftaler til Sundhed+',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p1:
    'Du afdrager i dag på én eller flere betalingsaftaler gennem Denti. Da Denti sammenlægges med Sundhed+, er det nødvendigt at overføre dine betalings-aftaler til Sundhed+.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_headline2:
    'Opret et gratis medlemskab',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p2:
    'I modsætning til Denti, er det hos Sundhed+ kun medlemmer der kan have en kredit.{0} For at kunne overføre dine betalingsaftaler til Sundhed+, er det derfor nødvendigt at du accepterer Sundhed+ medlemsbetingelser.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p3:
    'Medlemskabet af Sundhed+ er gratis og uforpligtende, og giver dig adgang til en lang række sundhedsrelaterede fordele.',
  migrateCredit_button_step4: 'Find behandler',
  migrateCredit_button_step4_expired: 'Læs mere om Sundhed+',
  migrateCredit_button_step4_migrate: 'Overfør kredit',
  migrateCredit_error_headline:
    'Vi kan ikke oprette dit medlemskab af Sundhed+ lige nu',
  migrateCredit_error_p1:
    'Vi kan desværre ikke oprette dit medlemskab af Sundhed+ og konvertere din Denti Kredit til en Sundhed+ Kredit. Det skyldes, at du har en eller flere ubetalte regninger hos Denti.',
  migrateCredit_error_p2:
    'Så snart du har betalt dit udestående, kan du igen anmode om at konvertere din Denti Kredit til en Sundhed+ Kredit, som du kan bruge til at finansiere sundhedsbehandlinger hos mere end 10.000 sundhedsbehandlere over hele landet.',
  migrateCredit_error_btn: 'Opdater betalingsoplysninger',
  migrateCreditLoadingMsg: 'Migrering af lån',
  migrateCreditIOSMessage:
    'Vi er blevet opmærksomme på, at iOS kan have problemer med at vise knappen i bunden. Hvis du oplever det samme problem, så prøv at ændre tekststørrelsen, da disse løsninger har hjulpet andre før.',

  bookingModalSolved_tittle:
    'Har bookingforespørgslen resulteret i at I har booket en tid til patienten?',
  bookingModalSolved_first_option: 'Ja, Der er booket en tid til patienten',
  bookingModalSolved_second_option:
    'Nej, Der er ikke booket en tid til patienten',
  bookingSolvedNotification: 'Denne bookingforespørgsel er markeret som løst',
  bookingModalDelete_tittle: 'Slet bookingforespørgsel',
  bookingModalDelete_message:
    'Er du sikker på at du vil slette denne bookingforespørgsel?',

  // Unsubscribe Booking Request Page

  unsubscribeBookingRequest_title: 'Afmeld bookingforespørgsler',
  unsubscribeBookingRequest_text1:
    'Er I sikre på, at I ønsker at fjerne muligheden for at brugere af behandlerindekset på {0} kan sende bookingforespørgsler til jeres klinik ?',
  unsubscribeBookingRequest_text2:
    'Hvis årsagen til at I ikke ønsker at modtage bookingforespørgsler på mail er fordi at I allerede har en onlinebooking - funktion på jeres hjemmeside, kan I i stedet overveje at blive oprettet som Sundhed + Partner. Det er helt gratis og uforpligtende og giver jer blandt andet mulighed for at tilpasse jeres {0} i behandlerindekset - herunder at lade “Book tid” - knappen pege direkte ind til jeres onlinebooking.',
  unsubscribeProfile: 'profil',
  unsubscribeBookingRequest_text3:
    'Kontakt kundeservice på {0} for at blive oprettet eller læs mere om fordelene {1}',
  unsubscribeBookingRequest_here: 'here.',
  unsubscribeBookingRequest_button: 'Afmeld',
  unsubscribeBookingRequest_error_title: 'Afmelding mislykkedes',
  unsubscribeBookingRequest_error_text:
    'Af sikkerhedsmæssige årsager er dette afmeldingslink ikke længere tilgængeligt.',
  unsubscribeBookingRequest_success_title: 'Afmeldt med succes',
  unsubscribeBookingRequest_success_text:
    'Du har afmeldt reservationsanmodninger og vil ikke blive generet igen.',

  // WEBSOCKET NOTIFICATIONS
  wsNotifPatientUpdateHeadline: 'Ændring registreret',
  wsNotifPatientUpdateText:
    'Der er registreret ændringer på {0} profil. Genindlæs patientens profil for at vise ændringerne.',
  wsNotifPatientUpdateButton: 'Genindlæs',
  wsNotifInvoiceAcceptedHeadline: 'Regningsbeløb godkendt',
  wsNotifInvoiceAcceptedText: '{0} har netop godkendt regningsbeløbet på {1}',
  wsNotifInvoiceDeclinedHeadline: 'Regning er afvist',
  wsNotifInvoiceDeclinedText:
    'Der er desværre blevet afvist en regning på {0} af {1}. Hvis dette er uventet, kontakt da venligst patienten.',
  wsNotifInvoiceAcceptedButton: 'OK',
  wsNotifNewInvoiceHeadline: 'Ny regning registreret',
  wsNotifNewInvoiceText:
    'Der er netop blevet registreret en regning på {0} på {1}.',
  wsNotifNewInvoiceButton: 'OK',
  wsNotifDunningInHeadline: 'Kreditten er midlertidigt lukket',
  wsNotifDunningInText:
    '{0} kredit er for nyligt lukket, og derfor kan der ikke oprettes nye regninger på vegne af {0} pt. {1} For yderligere information, venligst kontakt vores kundeservice, på {2} og {3}.',
  wsNotifDunningOutHeadline: 'Kreditten er aktiv igen',
  wsNotifDunningOutText:
    '{0} kredit er nu igen aktiv, og der kan oprettes nye regninger på vegne af {0}.',
  wsNotifDunningButton: 'OK',
  wsNotifRefundHeadline: 'Betaling refunderet',
  wsNotifRefundText: 'Regningsbeløbet på {0} kr er refunderet til patienten.',
  wsNotifRefundLink: 'Se lånedetaljer',
  wsNotifRefundButton: 'OK',
  wsNotifLoanDeletedHeadline: 'Et lån er slettet på patienten',
  wsNotifLoanDeletedText:
    'Der er blevet slettet et lån for denne patient.{0} Find yderligere information om sletningen i historikken for patienten.',
  wsNotifLoanDeletedBtn: 'Genindlæs',
  wsNotifLoanDeletedWithdrawalFailedHeadline: 'Trækningen fra kortet fejlede',
  wsNotifLoanDeletedWithdrawalFailedText:
    'Desværre fejlede trækningen fra betalingskortet, og derfor er omlægningen slettet.{0} Informer venligst patienten om dette, og forhåbentlig kan der findes en anden løsning.',
  wsNotifLoanDeletedWithdrawalFailedBtn: 'Genindlæs',
  referralTrue: 'Henvist fra egen læge',
  referralFalse: 'Ikke henvist fra egen læge',

  // Error Boundary
  errorBoundaryTitle: 'Sorry, there seems to be an issue',
  errorBoundaryText: 'Clear and try again',
  cookiesClearLoading: 'Clearing cookies...',
  cookiesClearDone: 'Cookies are cleared!',

  // Onboarding Flow
  onboardingGoBack: 'Tilbage',
  onboardingContinue: 'Fortsæt',
  onboardingFlowTitle: 'Bliv medlem',
  onboardingFlowSubtitle: 'Opret et gratis medlemskab af Sundhed+',
  onboardingJoinBtn: 'Opret medlemskab',
  onboardingFlowFirstInformation:
    'Du vil straks herefter få mulighed for at ansøge om en Sundhed+ Kredit. Det tager kun få minutter, og alt hvad du skal bruge er dit MitID.',
  onboardingCPRNumber: 'CPR-nummer',
  onboardingFirstNameTooShort: 'Fornavnet er for kort',
  onboardingLastNameTooShort: 'Efternavnet er for kort',
  onboardingAcceptMembership: 'Accepter medlemsbetindser',

  // smartlinks
  onboardingSmartlinkHeadline: 'Vælg behandlingssted',
  onboardingSmartlinkSubtitle: 'Behandleren har flere behandlingssteder.',
  onboardingSmartlinkSelect:
    'Vælg venligst det behandlingssted hvor du forventer at benytte kreditten.',
  onboardingSmartlinkNext: 'Fortsæt',
  onboardingSmartlinkNextError: 'Du skal vælge en filial for at fortsætte.',
  onboardingSmartlinkWrongCompanyChain:
    'Der er ingen filial for denne virksomhedskæde',

  // CPR number page
  onboardingStepTwoSubtitle:
    'Vi har brug for dit CPR-nummer til at hente dit navn og adresse i CPR-registret.',
  onboardingStepTwoOBS:
    'OBS: Dine kontaktoplysninger og dit cpr-nummer vil blive delt med de behandlersteder hvor du benytter kreditten.',
  onboardingStepTwoSeeMore: 'Hemmelig adresse? {0}',
  onboardingStepTwoSeeMoreLink: 'Se her',
  onboardingStepThree: 'Kigger efter data i Tinglysning',

  // have tou take out new loans
  onboardingHaveAlreadyTakeLoansTitle: 'Har du stiftet nye lån?',
  onboardingHaveAlreadyTakeLoansSubTitle1:
    'Har du optaget nye banklån eller forbrugslån efter 31. december {0} ?',
  onboardingHaveAlreadyTakeLoansSubTitle2:
    'Indtast venligst restgæld, samt totale månedlige ydelse på lånene.',
  onboardingHaveAlreadyTakeLoansInputDebt: 'Restgæld',
  onboardingHaveAlreadgTakeLoanRmainigDebt: 'Restgæld: {0}',
  onboardingHaveAlreadyTakeLoansDebtLessThanPayment:
    'Månedlig betaling må ikke overstige restgælden.',
  onboardingHaveAlreadyTakeLoansInputPayment: 'Månedlig ydelse',
  onboardingHaveAlreadyTakeLoansMontlytPayment: 'Månedlig ydelse: {0}',
  onboardingHaveAlreadyTakeLoansYes: 'Ja',
  onboardingHaveAlreadyTakeLoansNo: 'Nej',
  onboardingWhatTypeOfLoan: 'Hvilken type lån har du lige nu?',
  onboardingAddNewLoan: 'Tilføj lån',
  onboardingTypeLoanCar: 'billån',
  onboardingTypeLoanConsumer: 'Forbrugslån',
  onboardingTypeLoanHouse: 'Huslån',
  onboardingTypeLoanOther: 'Andet',

  // existing member

  onboardingExistingMemberTitle: 'Eksisterende medlem',
  onboardingExistingMemberSubtitle:
    'Du er gået i gang med at oprette et medlemskab af Sundhed+, men ud fra de indtastede oplysninger ser det ud til at du allerede er medlem.',
  onboardingExistingMemberQuestionsTitle: 'Spørgsmål til dit medlemskab?',
  onboardingExistingMemberQuestions:
    'Hvis du har spørgsmål til dit medlemskab, er du altid velkommen til at kontakte kundeservice på tlf. {0} eller på {1}.',
  onboardingExistingMemberApplyForCreditTitle: 'Ansøg om en Sundhed+ Kredit',
  onboardingExistingMemberApplyForCredit:
    'Hvis du ønsker at oprette en Sundhed+ Kredit som en del af dit medlemskab, eller allerede har en kredit og ønsker at forny den, kan du gøre det her.',
  onboardingCreateNewCredit: 'Opret kredit',

  //Existing client
  onboardingExistingClientTitle: 'Forny din kredit',
  onboardingExistingClientP1:
    'For at sikre ansvarlig långivning, skal alle medlemmer af Sundhed+ gennemføre et årligt kredittjek, hvis de fortsat ønsker at kunne trække på deres kredit.',
  onboardingExistingClientP2:
    'Kredittjekket har til formål at sikre, at der fortsat er luft nok i din økonomi til at du vil kunne betale den maksimale månedlige udgift, som en fuld udnyttelse af din Sundhed+ Kredit kan medføre.',
  onboardingExistingClientP3:
    'I kredittjekket vil vi derfor stille dig nogle få spørgsmål samt indhente data fra din bank og SKAT samt diverse offentlige registre. Vores system opstiller herefter et budget på dine vegne, ud fra den indsamlede data.',

  onboardingExistingClientExpiredCreditTitle: 'Bemærk',
  onboardingExistingClientExpiredP1:
    'Kredittjekket kan medføre, at din nuværende kreditramme og/eller debitorrente hæves eller sænkes.',
  onboardingExistingClientExpiredCreditP2:
    'Hvis kredittjekket medfører en ændring af din rente, vil denne ændring udelukkende slå igennem, hvis du vælger at trække yderligere på kreditten.',
  onboardingExistingClientExpiredCreditP3:
    'Hvis kredittjekket viser, at du ikke længere er kreditværdig, vil din kredit blive lukket.',

  onboardingRenewCredit: 'Forny kredit',
  onboardingRenewCreditCheck: 'Start kredittjek',

  // consent page

  onboardingConsentTitle: 'Her er hvad der skal ske...',
  onboardingConsentDataTitle: 'Indhentning af data',
  onboardingConsentDataText:
    'For at kunne tilbyde dig en Sundhed+ Kredit, skal vi være sikre på at du ikke optager gæld som du ikke er i stand til at betale tilbage.',
  onboardingConsentDataText2:
    'Vi vil derfor stille dig nogle få spørgsmål samt indhente data fra din bank og SKAT samt diverse offentlige registre. Vores system opstiller herefter et budget på dine vegne ud fra den indsamlede data.',
  onboardingConsentHandlingDataTitle: 'Håndtering af din data',
  onboardingConsentHandlingDataText:
    'Den data som vi indsamler benyttes udelukkende til at foretage en kreditvurdering af dig. Vi deler ikke dine data med hverken sundhedsbehandlere eller øvrige samarbejdspartnere. Se også vores {0}.',
  onboardingConsentHandlingDataLink: 'privatlivspolitik',
  onboardingJoinSundhedPlus:
    'Vi går op i din datasikkerhed, og du skal derfor vide at vi indsamler og behandler dine personoplysninger i overensstemmelse med vores {0}.',

  // how do you live

  // v1
  onboardingStepFiveTitle: 'Hvordan bor du?',
  onboardingStepFiveOption1: 'Lejebolig',
  onboardingStepFiveOption2: 'Andelsbolig',
  onboardingStepFiveOption3: 'Husejer',
  onboardingStepFiveOption4: 'Lejlighedsejer',
  onboardingStepFiveOption5: 'Bor hjemme',
  // v2
  onboardingAcommodationOption1: 'Husejer',
  onboardingAcommodationOption2: 'Lejlighedsejer',
  onboardingAcommodationOption3: 'Lejebolig',
  onboardingAcommodationOption4: 'Bor hjemme',
  onboardingAcommodationOption5: 'Andelsbolig',

  // civil status, v2
  onboardingCivilStatusTitle: 'Samlevende',
  onboardingCivilStatusSubtitle: 'Er du gift, eller bor du med en samlever?',
  onboardingCivilStatusOptionYes: 'Ja',
  onboardingCivilStatusOptionNo: 'Nej',

  // redirect to Monthio, v2
  onboardingRedirectToMonthioTitle: 'Indhentning af budgetposter',
  onboardingRedirectToMonthioP1:
    'Vi samarbejder med den danske virksomhed Monthio, der er godkendt af Finanstilsynet til at indsamle og analysere bankdata',
  onboardingRedirectToMonthioP2:
    'Når du klikker på knappen herunder, vil du derfor blive sendt videre til Monthios platform, hvor du med MitID skal give Monthio fuldmagt til at trække dine posteringerne fra dine bankkonti.',
  onboardingRedirectToMonthioP3:
    'Herefter vil Monthios system helt automatisk generere et budgetudkast, som du vil blive præsenteret for, og have mulighed for at tilrette, om nødvendigt.',
  onboardingRedirectToMonthioP4:
    'Hvis du gerne vil vente med denne del af ansøgningen, har vi også sendt dig en email med link, så du kan fortsætte ansøgningen på et senere tidspunkt.',
  onboardingRedirectToMonthioButton: 'Fortsæt',

  // evaluating budget, v2
  onboardingEvaluatingBudgetMessage: 'Indsamler budgetter',
  onboardingAnomalousWaitingTimeMessage:
    'Det lader desværre til at tage længere tid end forventet',

  // v2 DAM applications
  onboardingAcommodationRent: 'Lejebolig',
  onboardingAcommodationLivingAtHome: 'Hjemmeboende',
  onboardingAcommodationOwnerOccupied: 'Ejer hus/lejlighed',
  onboardingAcommodationHousingCooperative: 'Andelsbolig',

  // Monthio redirect
  onboardingRedirectToMonthio: 'Continue to Monthio',

  // check debtor register, v2
  onboardingCheckDebtorRegisterTitle: 'Check Debitor Registret',

  // how many kids
  onboardingStepSixTitle: 'Har du hjemmeboende børn under 18 år?',
  onboardingStepSixSubtitle:
    'Tilføj alle dine hjemmeboende børn under 18 år til listen herunder.',
  onboardingStepSixChooseAgeTitle: 'Hvor mange år er dit barn i dag?',
  onboardingStepSixButton: 'Tilføj barn',
  onboardingStepSixBarn: 'Barn',
  onboardingStepSixBarnAge: '{0} år',
  onboardingStepSixFjern: 'Fjern',

  // do you share your expenses

  onboardingStepSevenTitle: 'Deler du dine udgifter?',
  onboardingStepSevenSubtitle:
    'Deler du dine faste udgifter med en partner eller en sambo?',
  onboardingStepSevenOptionYes: 'Ja',
  onboardingStepSevenOptionNo: 'Nej',

  // how do you share your expenses

  onboardingStepEightTitle: 'Fordelingen af udgifter',
  onboardingStepEightSubtitle:
    'Angiv med slideren hvor stor en andel af de delte udgifter som du betaler',
  onboardingStepEightSlider: 'ca. {0} %',

  // do you have a car

  onboardingStepNineTitle1: 'Har du én eller flere biler?',
  onboardingStepNineTitle2: 'Hvad er dit ejerforhold til bilen?',
  onboardingStepNineTitle3: 'Hvad betaler du i månedlig ydelse på bilen?',
  onboardingStepNineSubtitle:
    'Tilføj biler, som du har udgifter på til listen. Det kan både være biler du ejer, leaser eller lejer.',
  onboardingStepNineButton: 'Tilføj bil',
  onboardingStepNineCarLease: 'Jeg leaser den',
  onboardingStepNineCarRent: 'Jeg lejer den',
  onboardingStepNineCarOwn: 'Jeg ejer den',
  onboardingStepNineCarCompany: 'Det er en firmabil',
  onboardingStepNineCar: 'Bil ({0})',
  onboardingStepNineMontlyPayment: 'Månedlig ydelse: {0}',
  onboardingStepNineEmptyAmoutField:
    'Angiv venligst din månedlige ydelse som du betaler til udlejer/leasingselskabet',

  // cpr failed

  onboardingCprFailedTitle: 'Det lykkedes ikke at hente dit navn og adresse',
  onboardingCprFailedSubtitle:
    'For at kunne oprette dit medlemskab, har vi behov for at kunne trække både dit navn og adresse fra cpr-registret.',
  onboardingCprFailedSecondTitle: 'Hemmelig adresse?',
  onboardingCprFailedSecondSubtitle:
    'Hvis du har hemmelig adresse, kan du nemt deaktivere din hemmelige adresse midlertidigt på {0} mens du foretager ansøgningen, og efterfølgende aktivere den igen. {1}',
  onboardingCprFailedBorgerDkLink: 'borger.dk',
  onboardingCprFailedSecondLink: 'Se vores guide til hvordan du gør det her',
  onboardingCprFailedParagraph:
    'Hvis du i øjeblikket bor i udlandet, eller af andre årsager ikke er registreret med en adresse i cpr-registret, kan vi desværre ikke tilbyde dig et medlemskab af Sundhed+ på nuværende tidspunkt.',

  // Monthly household expenses

  onboardingMonthlyHouseholdExpensesTitle: 'Månedlige udgifter til husholdning',
  onboardingMonthlyHouseholdExpensesHeadline:
    'Angiv venligst hvor mange penge du i gennemsnit bruger på husholding mv. pr. måned.',
  onboardingMonthlyHouseholdExpensesFooter:
    'Beløbet dækker over mad, drikke, dagligvarer, sko, tøj, husholdningsartikler, fritidsinteresser, fornøjelser, gaver, ferier, øvrige personlige udgifter som frisør og medicin, samt opretholdelse af varige forbrugsgoder (fx når du udskifter din telefon, computer eller cykel) og udforudsete udgifter.',
  onboardingSliderAmount: 'ca. {0}',

  // add missing budget items
  onboardingAddMissingBudgetItemsTitle: 'Manglende budgetposter',
  onboardingAddMissingBudgetItemsP1: `Det var desværre ikke muligt at finde alle budgetposter i dine bank posteringer. Derfor bedes du udfylde alle budget poster, som kan ses nedenfor. Du kan eventuelt sætte værdien til 0, såfremt den ikke er relevant.`,
  onboardingAddMissingBudgetItemsP2:
    'Vær opmærksom på, at dine inputs kan medføre krav til yderligere dokumentation.',
  onboardingAddMissingBudgetItemsHeadline: 'Udfyld følgende budgetposter',
  onboardingAddMissingBudgetCheckbox: 'Ingen udgift',

  // edit estimated budget
  onboardingEditEstimatedBudgetTitle: 'Dit forbrugsbudget',
  onboardingEditEstimatedBudgetP1:
    'Baseret på de indhentede informationer, har vi opstillet nedenstående forbrugsbudget over dine månedlige indtægter og udgifter. Du bedes gennemgå budgettet og foretage eventuelle rettelser, før du bekræfter at budgettet er retvisende for din personlige økonomi.',
  onboardingEditEstimatedBudgetP2:
    'Vær opmærksom på at dine rettelser kan medføre krav til yderligere dokumentation.',
  onboardingEditEstimatedBudgetBtn:
    'Jeg bekræfter, at jeg opfylder betingelserne',
  onboardingEditEstimatedBudgetDisclaimer:
    'Når du bekræfter budgettet, erklærer du samtidig at eventuel rettelser du har foretaget er korrekte og retvisende for din personlige økonomi. Det kan være ansvarspådragende hvis du afgiver urigtige oplysninger i din kreditansøgning.',
  onboardingConditionsCreditTitle: 'Betingelser for kredit',
  onboardingConditionsCreditText:
    'For at kunne blive godkendt til en Sundhed+ Kredit, skal du opfylde følgende minimumskrav:',
  onboardingConditionsCreditItem1: 'Minimum 18 år gammel',
  onboardingConditionsCreditItem2: 'Dansk folkeregisteradresse',
  onboardingConditionsCreditItem3: 'Dansk telefonnummer',
  onboardingConditionsCreditItem4:
    'Gyldigt Dankort, Visa/Visa Electron, Maestro eller MasterCard',
  onboardingConditionsCreditItem5:
    'Ikke registreret i skyldnerregistre, fx RKI, Debitor Registret og Kreditregisteret',
  onboardingConditionsCreditItem6: 'Ikke kontanthjælpsmodtager',
  onboardingConditionsCreditNote:
    'Bekræft venligst at du opfylder betingelserne.',

  // onboarding - Membership conditions

  onboardingMembershipConditionsTitle: 'Medlemsbetingelser',
  onboardingMembershipConditionsP1:
    'Som medlem af Sundhed+ får du adgang til en række tjenester og fordele. Medlemskabet er gratis, og kan til enhver tid opsiges.',
  onboardingMembershipConditionsP2:
    'Som en del af medlemskabet, vil du ca. 2 gange om måneden modtage vores medlemsnyhedsbrev, med inspirerende artikler om sundhed, samt eksklusive tilbud på sundhedsydelser fra sundhedsbehandlere i dit nærområde.',
  onboardingMembershipConditionsReadMore: 'Læs de fulde medlemsbetingelser',
  onboardingMembershipConditionsCheckbox:
    'Jeg erklærer, at jeg har læst og accepterer medlemsbetingelserne',

  // onboarding - Disposable Amount
  onboardingDisposableAmountTitle: 'Dit månedlige rådighedsbeløb',
  onboardingDisposableAmountText:
    'Hvor mange penge har du i gennemsnit tilbage hver måned, efter at alle dine udgifter er betalt?',
  onboardingDisposableAmountGuide:
    'Du skal angive det beløb som du fx ville kunne vælge at indbetale på en opsparingskonto hver måned.',

  // onboarding - Power of Attorney
  onboardingPowerOfAttorneyTitle:
    'Fuldmagt til indhentning af indkomst- og skatteoplysninger',
  onboardingPowerOfAttorneytext1:
    'Når du klikker "Fortsæt", vil du blive viderestillet til Skattestyrelsens hjemmeside, hvor du med MitID skal give fuldmagt til at vi må indhente dine indkomst- og skatteoplysninger',
  onboardingPowerOfAttorneytext2:
    'Opysningerne er nødvendige for at kunne foretage den lovpligtige kreditværdighedsvurdering af dig.',
  onboardingPowerOfAttorneytext3:
    'Efter at du har givet fuldmagt hos Skattestyrelsen, vil du automatisk blive sendt retur til din ansøgning.',
  onboardingPowerOfAttorneytext4:
    'Hvis du ikke ønsker at give fuldmagt til dine indkomst- og skatteoplysninger, kan vi desværre ikke tilbyde dig en Sundhed+ Kredit.',

  onboardingPowerOfAttorneySubtitleHasReturned:
    'Det ser ud til, at du er kommet retur fra Skattestyrelsens hjemmeside, uden at have givet fuldmagt til dine indkomst- og skatteoplysninger.',
  onboardingPowerOfAttorneyContainerHasReturnedTitle:
    'Oplever du tekniske problemer?',
  onboardingPowerOfAttorneyContainerHasReturnedText1:
    'Hvis du oplever tekniske udfordringer med at underskrive fuldmagten med MitID, kan problemet skyldes en gammel MitID-cookie på din enhed. Problemet kan i så fald løses ved at besøge denne {0}, og klikke på knappen "Slet cookies".',
  onboardingPowerOfAttorneyContainerHasReturnedTextPage: 'side',
  onboardingPowerOfAttorneyContainerHasReturnedText2:
    'Når du har slettet de gamle MitID-cookies, kan du forsøge at underskrive fuldmagten igen, ved at klikke på "Fortsæt".',

  // onboarding - How much for
  onboardingHowMuchForRentTitle: 'Husleje',
  onboardingHowMuchForRentText:
    'Vi er desværre ikke i stand til at identificere din husleje ud fra dine bankposteringer. Angiv venligst din månedlige husleje samt om beløbet inkluderer el, varme og/eller vand.',
  onboardingHowMuchForRentLabel: 'Månedlig husleje',
  onboardingHowMuchForRentIncludesElectricity: 'Husleje inkluderer el',
  onboardingHowMuchForRentIncludesHeating: 'Husleje inkluderer varme',
  onboardingHowMuchForRentIncludesWater: 'Husleje inkluderer vand',
  onboardingHowMuchForElectricityTitle: 'Elforbrug',
  onboardingHowMuchForElectricityText:
    'Vi er desværre ikke i stand til at identificere dit elforbrug ud fra dine bankposteringer. Angiv venligst din månedlige udgift til el herunder.',
  onboardingHowMuchForElectricityLabel: 'Elforbrug til el',
  onboardingHowMuchForHeatTitle: 'Varmeforbrug',
  onboardingHowMuchForHeatText:
    'Vi er desværre ikke i stand til at identificere dit varmeforbrug ud fra dine bankposteringer. Angiv venligst din månedlige udgift til varme herunder.',
  onboardingHowMuchForHeatLabel: 'Månedlige udgifter til varme',
  onboardingHowMuchForWaterTitle: 'Udgifter til vand',
  onboardingHowMuchForWaterText:
    'Vi er desværre ikke i stand til at identificere dine udgifter til vand ud fra dine bankposteringer. Angiv venligst din månedlige udgift til vand herunder.',
  onboardingHowMuchForWaterLabel: 'Månedlige udgifter til vand',
  onboardingHowMuchForPhoneTitle: 'Telefon',
  onboardingHowMuchForPhoneText:
    'Vi er desværre ikke i stand til at identificere dine udgifter til telefoni ud fra dine bankposteringer. Angiv venligst din månedlige udgift til telefoni herunder.',
  onboardingHowMuchForPhoneLabel: 'Udgift til telefon',
  onboardingHowMuchForPhoneCheckbox:
    'Min arbejdsgiver betaler mit telefonabonnement',

  // onboarding - membership created
  onboardingMembershipCreatedTitle: 'Velkommen i klubben!',
  onboardingMembershipCreatedP1:
    'Vi er glade for at kunne byde dig velkommen som nyt medlem i Sundhed+, og håber at du får glæde af de fordele som vores gratis medlemskab giver.',
  onboardingMembershipCreatedP2:
    'Du har nu mulighed for at ansøge om vores populære Sundhed+ Kredit, som giver dig mulighed for at dele betalingen for dine sundhedsbehandlinger op i mindre, overskuelige bidder.',
  onboardingMembershipCreatedBtn: 'Ansøg om kredit',
  onboardingMembershipCreatedLink:
    'Jeg ønsker ikke en kredit på nuværende tidspunkt',

  // onboarding - Prior approval
  onboardingPriorApprovalTitle: 'Forhåndsgodkendelse',
  onboardingPriorApprovalSubtitle:
    'Du opfylder kravene for at få en Sundhed+ Kredit',
  onboardingPriorApprovalP1:
    'Baseret på dit rådighedsbeløb og dine økonomiske nøgletal, vil du kunne blive godkendt til en Sundhed+ Kredit på {0}',
  onboardingPriorApprovalP2:
    'Før vi kan udstede kreditten, er det dog et lovkrav, at vi udarbejder et dokumenteret budget, og dermed sikrer os at det rådighedsbeløb du har oplyst er korrekt.',
  onboardingPriorApprovalP3:
    'I de følgende steps vil du derfor blive bedt om at svare på yderligere spørgsmål om din privatøkonomi samt give fuldmagt til at vi må hente dine bankposteringer, så vores system kan udarbejde et detaljeret forbrugsbudget for dig.',
  onboardingPriorApprovalSectionTitle: 'Sundhed+ Kredit',
  onboardingPriorApprovalSectionL1: 'Kreditbeløb:',
  onboardingPriorApprovalSectionL2: 'Debitorrente:',
  onboardingPriorApprovalSectionL3: 'Løbetid:',

  // onboarding - Prior approval v2
  onboardingPriorApprovalTitleV2: 'Forhåndsvurdering',
  onboardingPriorApprovalSubtitleV2:
    'Baseret på dit rådighedsbeløb og dine økonomiske nøgletal, vil du kunne blive godkendt til en Sundhed+ Kredit på.',
  onboardingPriorApprovalP1V2:
    'Før vi kan bestemme det eksakte kreditbeløb og udstede kreditten, er det dog et lovkrav, at vi udarbejder et dokumenteret budget over dine indtægter og udgifter, og dermed sikrer os at det rådighedsbeløb du har oplyst er korrekt.',
  onboardingPriorApprovalP2V2:
    'I de følgende steps vil du derfor blive bedt om at svare på yderligere spørgsmål om din privatøkonomi.',

  // onboarding - Check debtor records
  onboardingCreditOfferConclusionTitle: 'Kredittilbudskonklusioner',
  onboardingCreditOfferConclusionSubtitle:
    'Dit budget viser at du har et månedligt rådighedsbeløb på {0}',
  onboardingCreditOfferConclusionP1:
    'Da Beløbet er markant lavere end de {0} som du idligere oplyste, kan vi desværre kun tilbyde dig en kredit på {1}',
  onboardingCreditOfferConclusionP2:
    'For at færdiggøre aktiveringen bedes du uploade følgende dokumentation!',
  onboardingCreditOfferConclusionAlternativeP1:
    'Beløbet er tilstrækkeligt til, at vi kan tilbyde dig en kredit på {0} ',
  onboardingCreditOfferConclusionAlternativeP2:
    'Såfremt dokumentationen ikke matcher det indtastede beløb for budgetposten, vil kreditbeløbet blive justeret.',

  // onboarding - Approved credit
  onboardingApprovedCreditTitle: 'Tillykke',
  onboardingApprovedCreditSubtitle:
    'Du er blevet godkendt til en Sundhed+ Kredit',
  onboardingApprovedCreditP1:
    'Baseret på vores kreditvurdering af dig, er vi glade for at kunne tilbyde dig en Sundhed+ Kredit på {0}',
  onboardingApprovedCreditSmartLink:
    'Med kreditten har du mulighed for at dele betalingen op i mindre bidder hos {0} og mere end 10.000 andre sundhedsbehandlere over hele landet',
  onboardingApprovedCreditP2:
    'Med kreditten har du mulighed for at dele betalingen op i mindre bidder hos mere end 10.000 andre sundhedsbehandlere over hele landet',
  onboardingApprovedCreditConditionP1:
    'Da kreditvurderingen delvist er baseret på dine egne oplysninger, har vi dog brug for dokumentation for disse oplysninger før kreditten kan aktiveres',
  onboardingApprovedCreditConditionP2: 'Dokumentation som vi behøver: {0}',
  onboardingApprovedCreditSectionHeadline: 'Sundhed+ Kredit',
  onboardingApprovedCreditSectionL1: 'Kreditbeløb:',
  onboardingApprovedCreditSectionL2: 'Debitorrente:',
  onboardingApprovedCreditP3:
    'Vi har sendt dig en e-mail på {0}, med yderligere information om hvordan du kommer i gang med at benytte din kredit.',

  // onboarding - upload documentation
  onboardingUploadDocTitle: 'Upload dokumentation',
  onboardingUploadDocP1:
    'For at leve op til Finanstilsynets krav, er det nødvendigt at du indsender dokumentation for nedenstaende budgetposter, før vi kan aktivere din kredit.',
  onboardingUploadDocLearnMore: 'Læs om hvad du kan uploade som dokumentation',
  onboardingUploadDocSectionRENT: 'Husleje',
  onboardingUploadDocSectionHEAT: 'Varme',
  onboardingUploadDocSectionELECTRICITY: 'Elforbrug',
  onboardingUploadDocSectionWATER: 'Vand',
  onboardingUploadDocSectionPHONE: 'Phone',
  onboardingUploadDocBtnSection: 'Tilføj dokumentation',
  onboardingUploadMoreDocBtnSection: 'Tilføj fil',
  onboardingUploadNoteHeadline: 'Din note',
  onboardingUploadNoteBtnSection: 'Tilføj note',
  onboardingUploadSaveNoteBtn: 'Gem note',
  onboardingUploadTip:
    'Tilladte filtyper: pdf, jpeg eller gif. Max filstørrelse: 10 MB.',
  onboardingUploadDocBtnMoreSection: 'Tilføj yderligere dokumentation',
  onboardingUploadDocNoteHeading: 'Vil du tilføje en note til denne fil?',
  onboardingUploadDocYesBtn: 'Indsend',
  onboardingUploadDocNoBtn: 'Annuller',
  onboardingUploadDocModalSectionText: 'Beløb der skal dokumenteres',
  onboardingUploadDocBtn: 'Aktivere',
  onboardingUploadDocBottomText:
    'Har du brug for hjælp? {0} Ring til kundeservice på tlf. {1} mandag til fredag mellem kl. 9 - 16',

  onboardingUploadDeleteHeadline: 'Vil du slette dokumentet?',
  onboardingUploadDeleteYes: 'Ja',
  onboardingUploadDeleteNo: 'Nej',
  onboardingUploadDocStatusPending: 'Dokumentation afventer godkendelse',
  onboardingUploadDocStatusRejected: 'Handling påkrævet',
  onboardingUploadDocStatusApproved: 'Dokumentation godkendt',
  onboardingUploadDocRejectedNoteHeadline: 'Kommentar fra kundeservice',

  // upload statuses modals
  onboardingUploadLoadingHeadline: 'Uploader dokumentation',
  onboardingUploadLoadingSubtitle: 'Vent venligst...',
  onboardingUploadSuccessHeadline: 'Dokumentation indsendt',
  onboardingUploadSuccessSubtitle:
    'Du vil blive kontaktet så snart vi har gennemgået den indsendte dokumentation.',
  onboardingUploadErrorHeadline: 'Upload fejlet',
  onboardingUploadErrorP:
    'Gå tilbage og prøv igen, eller kontakt kundeservice for hjælp.',
  onboardingUploadErrorBtn: 'Tilbage',
  onboardingUploadValidationLoadingHeadline: 'Validerer fil',
  onboardingUploadValidationLoadingSubtitle: 'Vent venligst...',
  onboardingUploadValidationErrorHeadline: 'Den valgte fil er ugyldig',
  onboardingUploadValidationErrorP1: 'Filen er ikke tilføjet',
  onboardingUploadValidationErrorP2:
    'Filtypen skal være enten pdf, jpg eller png og filstørrelsen må ikke overstige 10 MB.',
  onboardingUploadValidationErrorOk: 'OK',

  onboardingUploadDuplicateErrorHeadline:
    'Det er ikke muligt at uploade flere filer med samme navn',
  onboardingUploadDuplicateErrorSubtitle: 'Filen blev ikke uploadet',
  onboardingUploadDuplicateErrorP1:
    'Klik "Ignorer", hvis filen allerede er uploadet, eller vælg "Omdøb", hvis vi skal omdøbe og gøre upload af filen mulig for dig.',
  onboardingUploadDuplicateErrorSkip: 'Ignorer',
  onboardingUploadDuplicateErrorRename: 'Omdøb',

  onboardingUploadAllCompletedHeadline: 'Godt gået',
  onboardingUploadAllCompletedSubtitle:
    'Du har nu indsendt dokumentation for alle poster',
  onboardingUploadAllCompletedP1:
    'Da du har indsendt din dokumentation i vores åbningstid, forventer vi at du vil blive kontaktet indenfor 1 time.',
  onboardingUploadAllCompletedP2:
    'Du kan derfor med fordel holde denne side åben, da du nemt og hurtigt vil kunne se dit svar her.',
  onboardingUploadAllCompletedBtn: 'Ok',

  // upload approved documentation
  onboadringUploadApprovedHedline: 'Tillykke',
  onboadringUploadApprovedSubtitle: 'Din dokumentation er nu godkendt',
  onboadringUploadApprovedP1:
    'Du vil nu modtage en mail med informationer omkring din kredit',
  onboadringUploadApprovedOk: 'OK',

  onboardingUploadCancelHeadline: 'Er du sikker på, at du vil annullere?',
  onboardingUploadCancelText:
    'Den tilføjede dokumentation vil i så fald ikke blive gemt.',
  onboardingUploadCancelYes: 'Ja',
  onboardingUploadCancelNo: 'Nej',

  onboardingDeleteFileHeadline:
    'Er du sikker på, at du vil slette dokumentationen fuldstændig?',
  onboardingDeleteFileText: 'Den uploadede dokumentation vil blive slettet.',
  onboardingDeleteFileYes: 'Ja',
  onboardingDeleteFileNo: 'Nej',

  onboardingWarningDeleteDocFailed: 'Dokumentet blev ikke slettet. Prøv igen.',
  onboardingWarningUpdateNoteFailed: 'Noten blev ikke gemt. Prøv igen.',
  onboardingEditNoteCancel: 'Annuller',
  onboardingEditNoteSave: 'Gem',
  onboardingEditNoteCancelHeadline: 'Vil du annullere din redigering?',
  onboardingEditNoteCancelText:
    'Vælg ja for at annullere din redigering og gå tilbage til den oprindelige tekst.',

  // upload step, toast notifications
  uploadStepToastSuccess: 'Dokumentationen for {0} er godkendt',
  uploadStepToastError:
    'Dokumentationen for {0} er mangelfuld og kræver yderligere handling',

  // upload guide
  uploadGuideStep1Title: 'Vi har brug for din hjælp',
  uploadGuideStep1P1:
    'Lovgivningen kræver, at vi indhenter "fyldestgørende dokumentation" for både indtægts- og udgiftsposter i dit budget.',
  uploadGuideStep1P2:
    'Vi har derfor brug for, at du indsender dokumentation for ét eller flere beløb i dit budget.',
  uploadGuideStep2Title: 'Hvad skal du sende?',
  uploadGuideStep2P1:
    'Dokumentationen finder du typisk i din digitale postkasse eller netbank, og kan fx bestå af:',
  uploadGuideStep2L1: 'Kontoudtog',
  uploadGuideStep2L2: 'Fakturaer/Kvitteringer',
  uploadGuideStep2L3: 'Betalingsservice-opgørelser',
  uploadGuideStep3Title: 'Vær opmærksom på at...',
  uploadGuideStep3P1:
    'Det er vigtigt, at den dokumentation, du indsender, både indeholder det beløb der skal dokumenteres, dit navn eller adresse samt den indtægt/udgift som beløbet relaterer sig til.',
  uploadGuideStep4Title: 'Et screenshot er helt fint',
  uploadGuideStep4P1:
    'Når du tilføjer dokumentation til en budgetpost, kan det både være i form af billeder, screenshots eller pdf-filer. Det er også muligt at tilføje mere end én fil, hvis du har behov for det.',
  uploadGuideStep5Title: 'Uddyb med en note',
  uploadGuideStep5P1:
    'Hvis du ikke har mulighed for at indsende fyldestgørende dokumentation for en budgetpost, eller ønsker at tilknytte en kommentar til de filer du indsender, så kan du med fordel tilføje en note.',
  uploadGuideOk: 'OK',
  uploadGuideGetStarted: 'Kom i gang',

  // onboarding - denied credit offer
  onboardingDeniedCreditTitle: 'Ansøgning afvist',
  onboardingDeniedCreditSubtitle:
    'Du kan desværre ikke godkendes til en kredit på nuværende tidspunkt',
  onboardingDeniedCreditWarningTitle: 'Årsag',
  onboardingDeniedCreditP1:
    'Vi beklager meget, men resultatet af vores kreditvurderingen er desværre, at vi ikke kan tilbyde dig en Sundhed+ Kredit på nuværende tidspunkt.',
  onboardingDeniedCreditP2:
    'Kontakt venligst kundeservice på tlf. {0} eller {1} for hjælp.',
  onboardingDeniedCreditP3:
    'Du har selvfølgelig stadig adgang til alle vores øvrige medlemsfordele, og vil fremover modtage vores nyhedsbrev med inspirerende artikler og eksklusive medlemstilbud ca. 2 gange månedligt.',
  onboardingDeniedCreditSubsectionHeadline: 'Kom godt i gang...',
  onboardingDeniedCreditSubsectionP1:
    'Vi har sendt dig en e-mail på {0}, med information om dit medlemskab og de fordele du kan se frem til som medlem af Sundhed+.',
  onboardingDeniedCreditBtn: 'Læs mere om fordelene',

  // onboarding - denied credit offer text mapping

  onboardingDeniedForPaymentRemarks:
    'Da vi efter opslag i Debitorregistret må konstatere, at du er registret med en betalingsanmærkning, kan vi desværre ikke tilbyde dig en kredit.',
  onboardingDeniedForPaymentRemarksP2:
    'Hvis du mener, at der er tale om en fejl, bedes du kontakte Debitorregistret direkte {0}.',
  onboardingDeniedForPaymentRemarksP3: 'her',

  onboardingDeniedForDisposableAmountBelow0:
    'Baseret på dit budget, må vi desværre konkludere, at din økonomi ikke på nuværende tidspunkt lever op til Finanstilsynets minimumskrav for kreditværdighed.',
  onboardingDeniedForDisposableAmountBelow0P2:
    'Hvis din økonomi skulle ændre sig i fremtiden, er du altid velkommen til at ansøge igen.',

  onboardingDeniedForCprMismatch:
    'Det oplyste CPR-nummer stemmer ikke overens med det CPR-nummer der er tilknyttet det MitID du har benyttet. Din ansøgning er derfor blevet afbrudt.',
  onboardingDeniedForCprMismatchP2:
    'Hvis du mener at der er sket en fejl, skal du ansøge på ny.',

  onboardingDeniedForSuspendedP1:
    'Du er tidligere blevet godkend til en kredit hos os (Sundhed+/Denti), men er i øjeblikket blokeret fra at optage yderligere kredit.',
  onboardingDeniedForSuspendedP2:
    'Dette kan skyldes, at du i mellemtiden er blevet registret i fx RKI eller Debitorregistret, eller at du ikke har betalt dine månedlige ydelser rettidigt.',
  onboardingDeniedForSuspendedP3:
    'Kontakt kundeservice, hvis du har spørgsmål til dette.',

  onboardingDeniedForClientIndebCollection:
    'Da du tidligere har misligholdt dine betalingsforpligtelser på en kredit, som du har haft gennem os (Sundhed+/Denti), må vi desværre afvise din ansøgning.',

  onboardingDeniedForClientHasNameAndAddressProtection:
    'Det ser ud til, at du har navne- og adressebeskyttelse, og så kan vi desværre ikke tilbyde en kredit.',

  onboardingDeniedGenericMessage:
    'Vi er desværre ikke i stand til at oplyse årsagen til, at din ansøgning er blevet afvist.',

  onboardingDeniedCreditHelp: 'Har du brug for hjælp?',
  onboardingDeniedCreditHelpPhone: 'Ring til kundeservice på tlf. {0}',
  onboardingDeniedCreditHelpTime: 'Mandag til fredag mellem kl. 9 - 15',
  onboardingActionButtonToSundhed: 'Tilbage til Sundhedplus.dk',

  // Loading spinner messages
  onboardingSpinnerTitle: 'Vent venligst...',
  onboardingSpinnerSubtitleRedirectingMitId:
    'Du viderestilles nu til Skattestyrelsen, hvor du med MitID kan give fuldmagt til dine skatteoplysninger.',

  // spinner before approved credit
  onboardingSpinnerBeforeApproveTitle: 'Vent venligst...',
  onboardingSpinnerBeforeApproveSubtitle:
    'Indtil videre ser alt godt ud, vi vil dog lige lave et allersidste tjek, som gerne skulle være meget hurtigt.',

  onboardingSpinnerSubtitleCprRegistration:
    'Vent et øjelik, mens vi henter dine data fra cpr-registret.',
  onboardingSpinnerSubtitleCalculatingBudget:
    'Vi beregner nu dit budget på baggrund af den indsamlede data',
  onboardingSpinnerMessageCalculatingBudget: 'Beregner budget..',
  onboardingSpinnerSubtitleCheckDebtRecords:
    'Vi har nu indhentet al den data vi skal bruge til at foretage en kreditvurdering, og vores kreditrobot er i fuld gang med at finde ud af om vi kan tilbyde dig en Sundhed+ Kredit.',
  onboardingSpinnerMessageCheckDebtRecords: 'Tjekker rådighedsbeløb',
  onboardingSpinnerTitleLinkBankAccount: 'Dine udgifter',
  onboardingSpinnerSubtitleEstablishBudget:
    'Vi opstiller nu dit budget, baseret på den indhentede data.',
  onboardingSpinnerMessageEstablishBudget: 'Opstiller budget...',
  onboardingSpinnerSubtitleEskatCompletes:
    'Vi indhenter nu din årsopgørelse og dine seneste lønsedler fra Skattestyrelsen, for at give dig en foreløbig vurdering af, om du vil kunne blive godkendt til en Sundhed+ Kredit.',
  onboardingSpinnerMessageEskatCompletes: 'Henter eSkat-data',
  onboardingSpinnerMessageEskatWaitTime: 'Det kan tage op til 15 sekunder',
  onboardingSpinnerSubtitleDataValidation:
    'Vi har nu indhentet al den data vi skal bruge til at foretage en kreditvurdering, og vores kreditrobot er i fuld gang med at finde ud af om vi kan tilbyde dig en Sundhed+ Kredit.',
  onboardingSpinnerMessageDataValidation: 'Beregner',
  onboardingSpinnerMessageDataValidation2: 'Analyserer data',
  onboardingSpinnerMessageDataValidation3: 'Opdaterer budget',
  onboardingSpinnerSubtitleLinkBankAccount:
    'Vi opretter nu forbindelse til tjenesten, hvor du kan give adgang til at autorisere os til at hente dine kontoudtog fra din bank',
  onboardingSpinnerMessageLinkBankAccount: 'Henter data',

  onboardingSpinnerSubtitleAnalyzeData:
    'Vi har nu indhentet al den data vi skal bruge til at foretage en kreditvurdering, og vores kreditrobot er i fuld gang med at finde ud af om vi kan tilbyde dig en Sundhed+ Kredit.',
  onboardingSpinnerMessageAnalyzeData: 'Tjekker rådighedsbeløb',
  onboardingSpinnerMessageCheckCPR: 'Tjekker CPR-nummer',
  onboardingSpinnerMessageCprGetData: ' CPR-data',
  onboardingSpinnerMessageTaxInformationLoader: 'Viderestiller',

  // onboarding - technical error
  onboardingTechnicalErrorTitle: 'Ansøgning afbrudt',
  onboardingTechnicalErrorSubtitle: 'Din ansøgning er desværre blevet afbrudt',
  onboardingTechnicalErrorP1:
    'Vi beklager meget, men der opstod desværre en teknisk fejl i forbindelse med kreditvurderingen. Derfor bedes du forsøge igen på et senere tidspunkt.',
  onboardingTechnicalErrorP2:
    'Kontakt venligst kundeservice på tlf. {0} eller {1} for hjælp.',
  onboardingTechnicalErrorP3:
    'Du har selvfølgelig stadig adgang til alle vores øvrige medlemsfordele, og vil fremover modtage vores nyhedsbrev med inspirerende artikler og eksklusive medlemstilbud ca. 2 gange månedligt.',
  onboardingTechnicalSubsectionHeadline: 'Kom godt i gang...',
  onboardingTechnicalSubsectionP:
    'Vi har sendt dig en e-mail på {0}, med information om dit medlemskab og de fordele du kan se frem til som medlem af Sundhed+.',

  // onboarding - invalid link for onboarding
  onboardingInvalidLinkTitle: 'Ugyldigt link',
  onboardingInvalidLinkP: `Du har desværre indtastet et ugyldigt link, som ikke kunne oversættes til en igangværende ansøgning. Hvis du ikke mener, at dette er korrekt, så kontakt venligst vores kundeservice på enten email, {0}, eller telefon, {1}.`,
  onboardingInvalidLinkButton: 'Start forfra',

  // onboarding - email verification
  onboardingEmailVerificationTitle: 'Bekræft din emailadresse',
  onboardingEmailVerificationSubtitle:
    'For at bekræfte din emailadresse, skal du indtaste den kode, som vi netop har sendt til {0}.',
  onboardingEmailVerificationP:
    'Vær opmærksom på at der somme tider kan gå op til 2 minutter før du modtager emailen.',
  onboardingEmailVerificationErrorTitle: 'E-mail-koden er forkert',
  onboardingEmailVerificationErrorP1:
    'For at oprette dit medlemskab skal vi kunne bekræfte din e-mail adresse. Desværre har du endnu ikke indtaste koden, som vi sendte til den indtastede email korrekt.',
  onboardingEmailVerificationErrorLabel: 'Bekræftelseskode',
  onboardingEmailVerificationErrorBtn: 'Send ny kode',

  onbordingEmailVerificationCodeText1:
    'Har du ikke modtaget en email med en bekræftelseskode? Dobbelttjek venligst at ovenstående emailadresse er korrekt, og at emailen ikke er havnet i dit spamfilter. Du kan anmode om en ny kode om',
  onboardingEmailVerificationCodeText2:
    'Har du ikke modtaget en email med en bekræftelseskode? Dobbelttjek venligst at ovenstående emailadresse er korrekt, og at emailen ikke er havnet i dit spamfilter. Send en ny kode til ',
  onboardingEmailVerificationSendNewCode: '{0}.',

  onboardingEmailConfirmationErrorMessage:
    'Koden "{0}" matcher ikke den kode du har modtaget på email. Forsøg venligst igen.',

  // onboarding - Link bank account Subaio
  onboardingLinkBankAccountTitle: 'Dine udgifter',
  onboardingLinkBankAccountP1:
    'For at kunne beregne dit månedlige rådighedsbeløb, har vi også behov for at kende dine faste udgifter.',
  onboardingLinkBankAccountP2:
    'Ved at give samtykke til at vi kan indhente dine kontoudtog fra din bank, vil vores system automatisk forsøge at identificere din faste månedlige udgifter.',
  onboardingLinkBankAccountP3:
    'Når du klikker på knappen herunder, åbnes et nyt vindue hvor du med MitID kan give fuldmagt til at vi må hente dine kontoudtog fra din bank.',
  onboardingLinkBankAccountP4:
    'Hvis du har konti i flere banker, det meget vigtigt at du giver adgang til alle bankerne, da vi ellers ikke kan opstille et retvisende budget.',
  onboardingLinkBankAccountButton1: 'Hent Kontoudtog',
  onboardingLinkBankAccountAccesAllBankMessage1:
    'Fik du tilføjet adgang til alle de banker du har konti i? I så fald er vi lige ved at være i mål!',
  onboardingLinkBankAccountAccesAllBankMessage2:
    'Bemærk: Det er ikke muligt at tilføje den samme bankkonto to gange.',
  onboardngLinkBankAccountButtonNext: 'Fortsæt',
  onboardngLinkBankAccountButtonAdditionalAccounts: 'Tilknyt yderligere konti',
  onboardngLinkBankAccountPopupClosedError:
    'Det ser ud til at du lukkede vinduet uden at færdiggøre tilknytningen til din bank. Forsøg venligst igen.',

  // onboarding - Loading with bullet list
  onboardingLoadingSubtitle:
    'Vi forbinder nu til Skattestyrelsen for at indhente din seneste årsopgørelse og informationer om din indkomst.',
  onboardingLoadingText1: 'Kigger efter data i Tinglysning',
  onboardingLoadingText2: 'Kigger efter data i Bilbogen',
  onboardingLoadingText3: 'Kigger efter data i Andelsbogen',
  onboardingLoadingText4: 'Kigger efter data i Geomatic',
  onboardingLoadingText5: 'Kigger efter data i BBR',
  onboardingLoadingText6: 'Udregner budgettet...',
  onboardingLoadingAnalyzingText1: 'Indhenter data fra SKAT',
  onboardingLoadingAnalyzingText2: 'Analyserer skattedata',
  onboardingLoadingAnalyzingText3: 'Bearbejder oplysninger',
  onboardingLoadingAnalyzingText4: 'Henter nødvendig information',
  onboardingLoadingAnalyzingText5: 'Vent venligst...',

  // onboarding - Edit budget types
  CATEGORY_NET_INCOME: 'Indkomst efter skat',
  NET_INCOME_TOTAL: 'Nettoindkomst',
  HOUSING_BENEFIT: 'Boligstøtte',
  CHILD_BENEFIT: 'Børnetilskud',
  CHILDREN_CHECK_AMOUNT: 'Børne - og ungeydelse (børnecheck)',
  ADDITIONAL_INCOME: 'Øvrige indtægter',

  CATEGORY_LIVING: 'Udgifter til bolig',
  HOUSE_LOAN_EXPENSES: 'Ydelse på boliglån',
  MORTGAGE_EXPENSES: 'Realkreditydelse',
  RENTAL_EXPENSES: 'Husleje/Boligafgift',
  COMMON_FIXED_EXPENSES: ' Fællesudgifter/grundejerforening',
  ELECTRICITY: 'Elektricitet',
  HEATING_EXPENSES: 'Varme ',
  WATER: 'Vand ',
  MAINTENANCE: 'Vedligehold',
  REAL_ESTATE_INSURANCE: 'Husforsikring',

  CATEGORY_TRANSPORT: 'Udgifter til transport',
  TRAIN_BUS_CARD: 'Offentlig transport',
  TRANSPORT_FUEL_EXPENSES: 'Brændstof',
  CAR_LEASING_COST: 'Leasingydelse/billeje',
  CAR_LOAN: 'Billån',
  CAR_INSURANCE: 'Bilforsikring',
  CAR_MAINTENENCE: 'Service på bil',
  CAR_OWNERSHIP_TAXES: 'Ejerafgift på bilen',
  FDMAAA: 'Vejhjælp',

  CATEGORY_DEBT: 'Udgifter til gæld(bortset fra realkredit- og billån)',
  EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN: 'Ydelse på banklån ',
  STUDENT_LOAN_EXPENSES: 'Afbetaling på SU-gæld',
  DebtSinceLastTaxReport: 'DebtSinceLastTaxReport',

  CATEGORY_OTHERS: 'Øvrige faste udgifter',
  SECURITY_SUBSCRIPTION: 'Alarm og sikkerhed',
  CHILDREN_SUPPORT: 'Børnebidrag',
  CHILDREN_COST: 'Daginstitution',
  HEALTH_INSURANCE: 'Sundhedsforsikring',
  INSURANCE: 'Forsikringer',
  INTERNET_SUBSCRIPTION: 'Internet',
  PHONE_SUBSCRIPTION: 'Telefon',
  TV_SUBSCRIPTION: 'TV ',
  FAGLIGT_KONTIGENT: 'Fagligt kontingent',
  OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES: 'Øvrige offentlige udgifter',
  ADDITIONAL_EXPENSES: 'Øvrige udgifter',

  CATEGORY_HOUSEHOLD: 'Dine udgifter til husholdning',
  HOUSEHOLD: 'Udgifter til husholdning',
  SPLIT_EXPENSES: 'Udgiftsdeling',

  correctedText: '(rettet)',
  onboradingAmountEdited: 'Beløbet er rettet af kunden',
  onboardingAmountFromReference: 'Beløbet er udregnet ud fra statistiske data',
  onboardingAmountFromESkat: 'Beløbet er fundet i din eSkat data',
  onboardingAmountFromPSD2: 'Beløbet er fundet i dine bank data',
  onboardingAmountFromClient: 'Beløbet er angivet af ansøgeren',
  onboardingAmountIncludedInRent:
    'Ansøgeren har angivet, at posten er inkluderet i huslejen',
  onboardingAmountPayedByEmployer:
    'Ansøgeren har angivet, at posten betales af arbejdsgiveren',
  onboardingAmountMissing: 'Beløbet kunne ikke beregnes',

  onboardingBudgetSplitExpensesTag: 'Deles med sambo',
  onboardingBudgetSplitExpensesP1: `Da du har oplyst, at du betaler {0} % af din husstands faste udgifter, vægter budgetposter mærket med {1} kun med {0} % i vores beregning af hvor stor en kredit din økonomi kan bære.`,

  // Documentation handling section
  documentationSideBarMenuItem: 'Dokumentation',
  documentationPlaceHolder: 'Dokumentation',
  documentationSeachPlaceholder: 'Søg efter dokumentation...',
  documentationMemberProfileButton: 'Medlemsprofil',
  documentationSeeApplicationButton: 'Se ansøgning',

  documentationUploadedDate: 'Uploadet d. {0}',
  documentationApproveBtn: 'Godkend',
  documentationDenyBtn: 'Afvis',
  documentationBudgetHeader: 'Budget',
  documentationBudgetBtn: 'Se det fulde budget',
  documentationDocumentPreviewBtn: 'Se på helsiden',
  documentationClientNote: 'Note',
  documentationClientNoteDefault: 'Ingen klientnotat',

  // payment agreement signature
  paymentAgreementSignatureLabel1: 'Aftalen er godkendt af',
  paymentAgreementSignatureLabel2: 'E-mail',
  paymentAgreementSignatureLabel3: 'IP-adresse',
  paymentAgreementSignatureLabel4: 'Enhedssignatur:',
  paymentAgreementSignatureLabel5: 'Beliggenhed',
  paymentAgreementSignatureTopBtnTextMitID: 'MitID-verificeret',
  paymentAgreementSignatureTopBtnTextNemIdAndMitId: 'NemID / MitID-verificeret',
  paymentAgreementSignatureBottomText1: 'Aftalen er godkendt digitalt af {0}',
  paymentAgreementSignatureBottomText2: '{0}, kl. {1} CET',
  documentationFilterStatus: 'Dokumentationsstatus',
  documentationFilterType: 'Dokumentationstype',
  documentationUpdateBtn: 'Opdater',

  documentationBudgetTypeInformation: 'Udgift til {0}',
  documentationBudgetReferenceBudget: 'Referencebudget',
  documentationBudgetRetrievedByNoitso: 'Indhentet af Noitso:',
  documentationBudgetInformedByCustomer: 'Oplyst af kunden:',

  documentationDocumentsUploaded: 'Dokumentation',
  documentationBtnApproveDocuments: 'Godkend dokumentation',
  documentationConfirmApproveDocuments:
    'Er du sikker på, at du vil godkende disse dokumenter?',

  documentationRequestDocumentation: 'Anmod om dokumentation',

  gotToDocumentList: 'Alle dokumenter',

  documentationNoteModalTitle: 'Send anmodning om yderligere dokumentation',
  documentationNoteModalButtonSend: 'Send anmodning',
  documentationNoteModalCloseTitle: 'Annuller anmodning?',
  documentationNoteModalButtonYes: 'Ja',
  documentationNoteModalButtonNo: 'Nej',

  documentationNoteCloseButton: 'Luk',
  documentationSupportNoteTitle: 'Sendte anmodninger',

  onboardingUpdatingValueLoader: 'Opdaterer',
  onboardingUpdatingValueLoader2: 'Vent venligst...',

  // documentation filter status
  documentationFilterApproved: 'Godkendt',
  documentationFilterPending: 'Verserende',
  documentationFilterRejected: 'Afvist',
  documentationFilterPlaceholder: 'Alle dokumentationstyper',

  // documentation budget
  documentationBudgetReference: 'Reference',
  documentationBudgetPSD2: 'PSD2',
  documentationBudgetFinal: 'Finale',
  documentationBudgetSource: 'Kilde',

  // aplications
  applicationsSideBarMenuItem: 'Ansøgninger',
  applicationsSeachPlaceholder: 'Søg efter ansøgninger',
  applicationsPlaceHolder: 'ansøgninger',

  // application filter status
  applicationFilterStatus: 'Ansøgnings status',
  applicationFilterInProgress: 'I gang',
  applicationFilterFailed: 'Fejlet',
  applicationFilterRejected: 'Afvist',
  applicationFilterDeprecated: 'Forældet',
  applicationFilterPendingManual: 'Manual behandling',
  applicationFilterPendingDocumentation: 'Afventer dokumentation',
  applicationFilterPendingActivation: 'Afventer aktivering',
  applicationFilterDone: 'Godkendt',

  // application page
  applicationsGoToList: 'Alle ansøgninger',
  applicationItemID: 'ID:',
  applicationItemDate: 'Dato:',
  applicationKebabMenuItem1: 'Medlemsprofil',
  applicationKebabMenuItem2: 'Noitso data',
  applicationKebabMenuItem2v2: 'Monthio data',
  applicationKebabMenuItem3: 'Årsopgørelse',
  applicationKebabMenuItem4: 'Godkend ansøgning',
  applicationKebabMenuItem5: 'Afvis ansøgning',
  applicationKebabMenuItem6: 'Monthio flow',

  applicationTimelineeSkat: 'eSkat',
  applicationTimeLineMonthio: 'Monthio',
  applicationTimeLineDebitorCheck: 'Debitortjek',
  applicationTimelineDocumentation: 'Dokumentation',
  applicationTimelineCompleted: 'Gennemført',

  applicationClientDetails: 'Personlig info',
  applicationClientPhone: 'Tlf. nr.',
  applicationClientCPR: 'CPR',
  applicationWarnings: ' Advarsler:',
  applicationBudgetStatus: 'Status',
  applicationBudgetReference: 'Reference',
  applicationBudgetBankData: 'Bankdata',
  applicationBudgetClientEntered: 'Brugerrettelse',
  applicationBudgetSplit: 'Delt',
  applicationBudgetFinal: 'Beslutning',
  applicationBudgetNoData: 'Afventer budgetdata',
  applicationBudgetSaveBtn: 'Gem rettelser',
  applicationBudgetDisposableAmount: 'Beløb til fri disposition',
  applicationBudgetDisposableCalculated: 'Beregnet',
  applicationBudgetInformedByApplicant: 'Oplyst af ansøger',
  applicationCarHeadline: 'Biler',
  applicationCarSubheadline: 'Leasing (mdl. ydelse)',
  applicationChildrenHeadline: 'Børn',
  applicationChildrenSubheadline: 'Alder',
  applicationChildrenAgeGroup1: 'Aldersgruppe 0-1',
  applicationChildrenAgeGroup2: 'Aldersgruppe 2-6',
  applicationChildrenAgeGroup3: 'Aldersgruppe 7-17',
  applicationCreditHeadline: 'Kredit',
  applicationCreditMaxCredit: 'Max kredit',
  applicationCreditMinDuration: 'Min. løbetid',
  applicationCreditInterestRate: 'Rentesats',
  applicationCreditMiscellaneous: 'Diverse',
  applicationCreditHouseType: 'Boligtype',
  applicationCreditCostSharing: 'Udgiftsdeling',
  applicationCreditDebtMonth: 'Mdl. ydelse nye lån',
  applicationIncomeHeadline: 'Indkomst og gæld',
  applicationIncomePay: 'Løn',
  applicationIncomeDebt: 'Gæld',
  applicationIncomeDebtRation: 'Gældsratio',
  applicationProperties: 'Ejendomme',
  applicationPropertiesAddress: 'Adresse',
  applicationBudgetQuantity: 'Antal',
  applicationChildrenYears: 'år',
  applicationIncomeNetSalary: 'Nettoløn:',
  applicationIncomeGrossSalary: 'Bruttoløn:',
  applicationIncomeSalaryType: 'Løntype:',
  applicationIncomeSalaryAverage: 'Gennemsnitlig nettoløn:',
  applicationApproveHeadline: 'Godkend ansøgning',
  applicationApproveText:
    'Når du godkender ansøgningen, bekræfter du, at du har gennemgået ansøgers budget, at budgettet er fyldestgørende, og at ansøger er kreditværdig.',
  applicationApproveComment: 'Kommentar til godkendelse',
  applicationDenyBtn: 'Afvis',
  applicationApproveBtn: 'Godkend',
  applicationCancelActionBtn: 'Annuler',
  applicationApproveDenyReasonHeadline: 'Afvis ansøgning',
  applicationApproveDenyReasonNote: 'Skriv en kort begrundelse for afvisning',
  applicationApproveDenyReasonPlaceholer:
    'Skriv en kort begrundelse for at afvise ansøgningen...',
  applicationApprovedModalConclusionSectionMonthly: 'Minimumsløbetid',
  applicationApprovedModalConclusionSectionMonthlyPayment: 'Månedlig ydelse',

  // application warning options
  applicationWarningOption1Headline: 'Ansøgeren er kontanthjælpsmodtager',
  applicationWarningOption1Description:
    'Ansøgeren er kontanthjælpsmodtager. Dette er en økonomisk udsat gruppe, så her er det vigtigt at være ekstra opmærksom inden ansøgningen eventuelt godkendes./The applicant is getting unemployment assistance. People in this group are usually in a vulnerable position, so it is important to be extremely cautious before the credit application potentially is approved.',
  applicationWarningOption2Headline:
    'Manglende lønseddel inden for sidste halve år',
  applicationWarningOption2Description:
    'Der er fundet afvigelser i lønsedlerne for det seneste år, mindst en månedsløn mangler. Vær ekstra opmærksom på dette, da det er vigtigt at afklare om, det eventuelt kan ske igen. Dette skal afklares inden, at ansøgningen kan godkendes.',
  applicationWarningOption3Headline: 'Ansøgeren er i restance',
  applicationWarningOption3Description:
    'Ansøgeren er i restance, altså har gæld til det offentlig ifølge årsopgørelsen. Det er vigtigt, at det afklares og eventuelt tages med i overvejelserne, når der kigges på budgettet.',

  // application payslips salary types
  payslipsType00: 'Normal lønindkomst (f.eks. funktionære)',
  payslipsType01: 'Uddannelsesydelse (SU)',
  payslipsType03: 'Indkomst under reglerne vedr. Grønlandsskat',
  payslipsType04:
    'Anden personlig indkomst, Fx dagpenge, G-dages-godtgørelse, pension, kommunal ressourceforløbsydelse, kommunalt flekslønstilskud, anden overførselsindkomst',
  payslipsType05:
    'B-indkomst. Hvis personen samtidig har fået A-indkomst, så bruges indkomsttype',
  payslipsType06: 'Kontanthjælp',
  payslipsType07:
    'Syge- og barselsdagpenge udbetalt af det offentlige, direkte til personen.',
  payslipsType08: 'Forskerordningen - beskatning efter KSL §48E - F',
  payslipsType09:
    'Am-bidragsfri og A-skattefri A-indkomst. Fx DIS-indkomst, danske virksomheders udenlandske medarbejderes løn for arbejde udført i udlandet.',
  payslipsType20:
    'Indkomst fra AFU-fonden. Må udelukkende anvendes af AFU-fonden.',
  payslipsType24:
    'Som indkomsttype 04, men for ydelser, hvori der ikke kan ske lønindeholdelse.',
  payslipsType26:
    'A-indkomst mv. fra aldersopsparing - forventes oprettet medio 2018. Indkomsttypen skal indtil videre kun bruges af udbydere af arbejdsgiveradministrerede aldersopsparinger, sammen med felt 57 og 58.',

  //update budget from application loading
  applicationUpdateBudgetLoadingHeadline: 'Opdaterer budget',
  applicationUpdateBudgetLoadingSubtitle: 'Vent venligst...',

  applicationDocumentationStatusRequired: 'Mangler',
  applicationDocumentationStatusPending: 'Ubehandlet',
  applicationDocumentationStatusApproved: 'Godkendt',
  applicationDocumentationStatusRejected: 'Mangelfuld',

  // onboarding restart modal
  onboardingRestartApplicationHeadline: 'Vil du fortsætte hvor du slap?',
  onboardingRestartApplicationText:
    'Det ser ud til at du allerede har påbegyndt en ansøgning om en Sundhed+ Kredit. Vil du fortsætte ansøgningen, eller starte forfra?',
  onboardingRestartApplicationSuccess: 'Fortsæt',
  onboardingRestartApplicationCancel: 'Start forfra',

  // Manual intervention Onboarding v2

  manualInterventionTitle: 'Budget indsendt',
  manualInterventionSubheadline: 'Dit budget er indsendt til manuel gennemgang',
  manualInterventionP1:
    'Vores flinke kreditmedarbejdere sidder klar til at behandle kreditansøgninger alle hverdage mellem 8 og 16. Du vil høre fra os, så snart vi har gennemgået din sag.',
  manualInterventionP2:
    'Hvis du i mellemtiden har spørgsmål til din ansøgning, eller til Sundhed+ Kredit generelt, er du altid velkommen til at kontakte kundeservice på tlf. {0} eller {1}.',
  manualInterventionButton: 'Afslut',

  // Onboarding errors
  // Create member error
  createMemberErrorTitle: 'Øv, der opstod en teknisk fejl',
  createMemberErrorP1:
    'Det lader til, at der er noget i din indtastede data, som driller systemet.',
  createMemberErrorP2: 'Kontakt venligst os på {0} eller tlf. {1}',

  // Create member error
  confirmEmailLinkError: 'Emailen er allerede bekræftet',
  confirmEmailAlreadyConfirmedError: 'Linket er ikke længere gyldigt',

  confirmEmailAlreadyConfirmedP1:
    'Det ser ud til at du allerede har bekræftet din email',
  confirmEmailAlreadyConfirmedP2:
    'Hvis du har brug for hjælp, er du altid meget velkommen til at kontakte os på {0} eller tlf. {1}',

  //withBackCheck
  withBackCheckMessage:
    'Det er desværre ikke muligt at gå tilbage til forrige step',

  // cache busting confirm modal
  cacheBustingConfirmModalTitle: 'Ny opdatering klar',
  cacheBustingConfirmModalText:
    'Vi har netop foretaget nogle forbedringer i systemet. Før du kan få glæde af forbedringerne, skal siden dog genindlæses.{0} Hvis du er midt i noget, så kan du blot trykke på "Ikke nu". Så minder vi dig om det igen om fem minutter.',
  cacheBustingConfirmModalConfirmButton: 'Opdatér',
  cacheBustingConfirmModalCancelButton: 'Ikke nu',

  // Registration Invoice flow
  registerInvoiceFlowLookupPatientTitle: 'Fremsøg medlem',
  registerInvoiceFlowLookupPatientSubTitle:
    'Indtast cpr-nummer og de 2 første bogstaver i fornavnet på det Sundhed+ medlem som ønsker at betale med sin Sundhed+ Kredit.',
  registerInvoiceFlowLookupPatientParagraph:
    'Hvis det er første gang du registrerer et regningsbeløb på dette medlem, vil medlemmet modtage en notifikation om at {0} nu har fået adgang til medlemmets Sundhed+ Kredit.',

  registerInvoiceFlowButton: 'Find medlem',
  registerInvoiceFlowMembersCPR: 'Medlemmets cpr-nummer:',
  registerInvoiceFlowMemberName: 'De to første bogstaver i medlemmets fornavn:',
  registerSeeProfileButton: 'Se medlemsprofil',
  registerInvoiceButton: 'Registrer regningsbeløb',

  registerInvoiceFlowPatientInfoTitle: 'Medlemmets kredit',

  registerInvoiceFlowCprLabel: 'CPR-nr.:',
  registerInvoiceFlowCreditStatus: 'Kreditstatus:',
  registerInvoiceFlowExpirationDate: ' ({0} dage).',
  registerInvoiceFlowExpirationDateforNonActiveCredit: '{0}.',
  registerInvoiceFlowCreditAmount: 'Kreditbeløb til rådighed:',
  registerInvoiceFlowCreditExpired: 'Udløbsdato:',

  registerInvoiceFlowTitleCreditInactive: 'Patientens kredit er inaktiv',
  registerInvoiceFlowButtonClosed: 'Luk',
  registerInvoiceFlowNote: 'Note:',

  registerInvoiceNoteTitleForPendings: 'Kreditten er midlertidigt blokeret',
  registerInvoiceNoteMessage:
    'Da der er registreret et regningsbeløb på kreditten, som afventer medlemmets godkendelse, er kreditten midlertidigt blokeret for yderligere registreringer.',

  registerInvoiceNoteTitleForExpiredCredit: 'Kreditten er udløbet',
  registerInvoiceNoteMessageForExpiredCredit:
    'Medlemmets kredit er udløbet. Hvis medlemmet ønsker at genaktivere kreditten, skal medlemmet gennemføre en ny kreditværdighedsvurdering.',
  registerInvoiceSmsButtonToNewAssesmentLink:
    'Send sms med genaktiveringslink til medlemmet',
  // register InvoiceFlow Statuses
  registerInvoiceFlowStatusClosed: 'Lukket',
  registerInvoiceFlowStatusRejected: 'Afvist',
  registerInvoiceFlowStatusInactive: 'Inaktiv',
  registerInvoiceFlowStatusSuspended: 'Suspenderet',
  registerInvoiceFlowStatusActive: 'Aktiv',
  registerInvoiceFlowStatusDebtCollection: 'Sendt til inkasso',
  registerInvoiceFlowStatusExpired: 'Udløbet',

  registerInvoiceNoteTitleForStatusClosed: 'Kreditten er lukket',
  registerInvoiceNoteMessageForStatusClosed:
    'Medlemmets kredit er blevet lukket. Det er derfor ikke muligt at registrere yderligere regningsbeløb på denne kredit.',

  // mitSundhed+ - Dashboard
  mitSundhedDashboardTitle: ' Velkommen til Mit Sundhed+',
  mitSundhedDashboardSubTitle:
    'Her kan du se dit budget, dine kreditoplysninger og meget mere.',
  mitSundhedDashBoardingLoginButton: 'Log på',

  mitSundhedMenuItemKredit: 'Sundhed+ kredit',
  mitSundhedMenuItemProfile: 'Medlemsprofil',
  mitSundhedMenuItemHelp: 'Hjælp',

  mitCreditTitle: 'Din Sundhed+ Kredit',
  mitCreditTitleSmallScreen: 'Din kredit',
  mitCreditStatus: 'Kreditstatus',
  mitCreditAvailable: 'Kredit til rådighed',
  mitCreditExpiration: ' - udløber {0}',
  mitInterestRate: 'Debitorrente',
  mitCreditLimit: 'Kreditramme',

  // Credit Status Mapping Text
  mitCreditStatusActive: 'Aktiv',
  mitCreditStatusFullyUtilized: 'Fuldt udnyttet',
  mitCreditStatusInactive: 'Inaktiv',
  mitCreditStatusExpired: 'Udløbet',
  mitCreditStatusRejected: 'Afvist',
  mitCreditStatusDisabled: 'Ingen kredit',
  mitCreditStatusSuspended: 'Suspenderet',
  mitCreditStatusLatePayment: 'For sen betaling',
  mitCreditStatusClosed: 'Lukket',

  mitWarningCardForMissingCreditCard:
    'Du har ikke tilføjet et betalingskort til din kreditaftale. Du skal tilføje et betalingskort, for at vi kan trække din månedlige ydelse.',
  mitCreditCardNotActive:
    'Dit betalingskort er spærret/udløbet, og du skal derfor tilknytte et nyt betalingskort til din kreditaftale, for at vi kan trække din månedlige ydelse.',
  mitCreditCardInsufficientFunds:
    'Vi har ikke været i stand til at trække den seneste ydelse på dit tilknyttede betalingskort. Sørg venligst for at få dækning på kortet, eller tilføj et nyt betalingskort til din betalingsaftale.',
  mitNoteForLatePayment:
    'Grundet misligholdelse, er din kredit blevet lukket. Kontakt Sundhed+ Kundeservice hvis du har spørgsmål.',
  mitWarningCardForDisabledAndRejectedWithNoActiveAgreement: `Når du opretter en Sundhed+ Kredit, får du adgang til en fleksibel kredit på op til ${creditMaxAmountApproveCreditForWarningCards} kr, som du kan bruge til at dele betalingen for dine sundhedsbehandlinger op i mindre bidder.`,
  mitNoteForExpiredCredit:
    'Din Sundhed+ Kredit er udløbet. Hvis du ønsker at genaktivere din Sundhed+ Kredit, så du også fremadrettet kan dele betalingen for dine sundhedsbehandlinger op i mindre bidder, skal du gennemføre et fornyet kredittjek.',
  mitCreditIsFullyUtilized:
    'Din kreditramme er på nuværende tidspunkt fuldt udnyttet, hvilket betyder, at der ikke kan trækkes yderligere på din kredit.',
  mitButtonForDisabledNote: 'Ansøg om en kredit',
  mitRenewCreditCard: 'Tilføj betalingskort',
  mitStartCheckCreditButton: 'Start Kredittjek',

  mitCreditInfoTitle: 'Kreditinfo',
  mitCreditInfoP1:
    '  Du kan benytte din kredit til at betale for sundhedsbehandlinger hos alle Sundhed+ Partnere, så længe kreditten er aktiv og der er kredit til rådighed. Kreditten er aktiv i 12 måneder fra oprettelsesdatoen - herefter skal du gennemføre et fornyet kredittjek, hvis du fortsat ønsker at kunne trække på kreditten.',
  mitCreditInfoP2:
    'Når der er mindre end 6 måneder til at kreditten udløber, åbnes der op for at du kan forlænge kreditten med yderligere 12 måneder ved at gennemføre et kredittjek. Vær dog opmærksom på, at kredittjekket kan medføre at du ikke længere vurderes kreditværdig, og at du dermed mister adgangen til at trække yderligere på din kredit. Kredittjekket kan også medføre at din kreditramme hæves eller sænkes',
  mitCreditInfoDunningP1:
    'Din kredit er i øjeblikket suspenderet. En Sundhed+ Kredit suspenderes automatisk hvis du er bagud med én eller flere betalinger, eller hvis din betalingshistorik over de seneste 6 måneder indikerer, at din økonomi i øjeblikket ikke kan bære en højere månedlig ydelse end den du i øjeblikket betaler.',
  mitCreditInfoDunningP2:
    '{0} kan Sundhed+ også til enhver tid vælge at suspendere en kredit af andre årsager.',
  mitCreditInfoDunningCreditAgreement: 'Jf. aftalebestemmelserne',
  mitCreditInfoSuspended:
    'Din kredit er lukket, og kan ikke længere benyttes. Kontakt Sundhed+ Kundeservice, hvis du har spørgsmål til lukningen.',

  mitCreditAgreementTitle: 'Aktive betalingsaftaler',
  mitCreditAgreementTotalDebt: 'Samlet restgæld',
  mitCreditAgreementTableCreated: 'Oprettet',
  mitCreditAgreementTableIMontly: 'Mnd. ydelse',
  mitCreditAgreementTableItemInterest: 'Rente',
  mitCreditAgreementTableItemOustandingDebt: 'Restgæld',
  mitCreditAgreementTableItemLoanAmount: 'Lånebeløb',

  tableEmptyList: 'Ingen aktive betalingsaftaler',
  tablePaymentAgreementsEmptyTable: 'Ingen aktive betalingsaftaler',
  tableBillingHistoryEmptyTable: 'Ingen registrerede regninger',

  mitPaymentDetailsTitle: 'Betalingsoplysninger',

  mitPaymentDetailsMonthlyPayment: 'Månedlig betaling',
  mitPaymentDetailsLastPayment: 'Senest betalt',
  mitPaymentDetailsLinkedPaymentCard: 'Tilknyttet betalingskort',
  mitPaymentDetailsCardStatus: 'Kortstatus',
  mitUpdateCreditCard: 'Opdater betalingskort',

  mitBillingHistoryTitle: 'Regningshistorik',
  mitBillingHistoryDate: 'Dato',
  mitBillingHistoryClinicAssociated: 'Behandler',
  mitBillingHistoryStatus: 'Status',
  mitBillingHistoryAmount: 'Regningsbeløb',

  mitPractitionerAccessTitle: 'Behandleradgang',
  mitPractitionerAccessP1:
    'Alle behandlere der samarbejder med Sundhed+, har mulighed for at få adgang til din kredit og registrere et regningsbeløb på kreditten. Når en behandler fremsøger din kredit første gang, vil du blive underrettet via sms, og behandleren vil fremgå af listen herunder.',
  mitPractitionerAccessP2:
    'Hvis du fjerner en behandlers adgang til din kredit, kan behandleren godt fremsøge din kredit igen, men du vil i så fald få en ny underretning via sms.',

  mitPractitionerButton: 'Fjern adgang',
  mitPractitionerAccessPage: 'Adgang siden',

  mitDocumentSectionTitle: 'Aftaledokumenter',
  mitCreditTermsLabel: 'Aftalebestemmelser',

  // memberProfile
  mitMemberProfileTitle: 'Medlemsprofil',
  mitPersonalInformation: 'Personlig info',

  // billing history mapping text status
  billingHistoryStatusWaitingForSignature: 'Afventer godkendelse',
  billingHistoryStatusAccepted: 'Godkendt',

  // client portal error messages generic
  clientPortalErrorTitle: 'Noget gik galt...',
  clientPortalErrorP1:
    'Der opstod desværre et teknisk problem med dit login. Prøv venligst igen, eller kontakt Sundhed+ Kundeservice hvis problemet fortsætter.',
  clientPortalErrorButton: 'Prøv igen',
  clientPortalErrorHelpMsg: 'Brug for hjælp?',

  // client portal error messages for not client
  clientPortalErrorNotClientTitle: 'Login fejlede',
  clientPortalErrorNotClientP1:
    'På nuværende tidspunkt er Medlemsportalen forbeholdt medlemmer med en Sundhed+ Kredit.',
  clientPortalErrorNotClientP2:
    'Er du allerede medlem af Sundhed+, og ønsker at ansøge om en Sundhed+ Kredit? {0}',
  clientPortalErrorNotClientStartApplication: 'Start ansøgning',
  clientPortalErrorNotClientP3: 'Endnu ikke medlem? {0}',
  clientPortalErrorNotClientCreateAccount: 'Opret et gratis medlemskab',
  clientPortalErrorButtonGoBackToSundhed: 'Tilbage til sundhedplus.dk',

  // link missing page modal
  linkMissingModalTitle: 'Linket er udløbet',
  linkMissingModalP1:
    'Det ser ud til at du har klikket på et gammelt link, som ikke længere er aktivt.',
  linkMissingModalP2:
    'Du kan eventuelt forsøge at finde det du leder ved at gå til forsiden af sundhedplus.dk.',
  linkeMissingModalP3:
    'Alternativt er du også velkommen til at kontakte Sundhed+ kundeservice på {0} eller på tlf. {1}.',

  // welcome page
  welcomePageTitle: 'Velkommen til Partnerportalen',
  welcomePageSubTitle: 'Kom hurtigt i gang:',
  // patient with credit
  welcomePageCardCreateInvoiceTitle: 'Patient med kredit',
  welcomePageCardCreateInvoiceP1:
    'Har du en patient med en eksisterende Sundhed+ Kredit? Registrer regningsbeløbet på patientens kredit med få klik.',
  welcomePageCardCreateInvoiceButton: 'Registrér beløb',
  //patient without credit
  welcomePageCardSendApplicationLinkTitle: 'Patient uden kredit',
  welcomePageCardSendApplicationLinkP1:
    'Har du en patient, som ønsker at ansøge om en Sundhed+ Kredit? Send patienten en e-mail eller sms med et link til at ansøge.',
  welcomePageCardSendApplicationLinkButton: 'Send ansøgningslink',

  welcomePageContactInfoP1:
    'Tøv ikke med at kontakte os, hvis du har spørgsmål.',
  welcomePageContactInfoP2: 'Du fanger os på tlf. {0} eller på {1}',

  // send renew link modal
  sendRenewLinkModalTitle: 'Send fornyelseslink',
  sendRenewLinkModalP1:
    'For at sikre ansvarlig långivning, skal alle medlemmer af Sundhed+ gennemføre et årligt kredittjek, hvis de fortsat ønsker at kunne trække på deres kredit.',
  sendRenewLinkModalP2:
    'Du kan hjælpe medlemmet med at få genaktiveret sin kredit, ved at sende medlemmet et direkte link til at foretage kredittjekket.Kredittjekket kan gennemføres både på computer og mobil, og kræver ikke andet end MitID.',
  sendRenewLinkModalCheckBoxTitle: 'Vælg hvortil linket skal sendes',
  sendRenewLinkModalCheckBoxSMS: 'Send på sms ({0})',
  sendRenewLinkModalCheckBoxEmail: 'Send på e-mail ({0})',
  sendRenewLinkModalBtnCancel: 'Fortryd',
  sendRenewLinkModalBtnSend: 'Send link',

  // send renew link modal success
  sendRenewLinkModalSuccessTitle: 'Linket er sendt',
  sendRenewLinkModalSuccessSubTitle: 'Vær opmærksom på følgende...',
  sendRenewLinkModalSuccessInfoBox1:
    'Ansøgninger om fornyelse behandles som udgangspunkt indenfor 15 minutter på hverdage mellem kl. 9 og 15.',
  sendRenewLinkModalSuccessInfoBox2:
    'Kredittjekket kan medføre at medlemmets nuværende kreditramme hæves eller sænkes.',
  sendRenewLinkModalSuccessInfoBox3:
    'Hvis kredittjekket viser, at medlemmets økonomi ikke længere lever op til Finanstilsynets minimumskrav for kreditværdighed, vil medlemmets kredit desværre ikke kunne genaktiveres.',

  sendRenewLinkModalBtnClose: 'Luk',
};

export default da;
